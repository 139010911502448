import axios from "axios";
import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import convertTimeToCountry from "../../utility/convertTimeToCountry";
import ErrosToast from "../../components/ErrosToast";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

const CsvRow = ({
  entry,
  index,
  sendToSent,
  setCsvSent,
  setCsvUnsent,
  csvSent,
  csvUnsent,
  setIsUpdateShipmentNo,
  updateData,
  isChanged,
  handleFilter,
  isVisibleArray,
  setIsVisibleArray,
  popupRef,
}) => {
  const company_shipment_num = entry.csn;
  const [companyShipmentNo, setCSN] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(!!entry.csn);

  const [generating, setGenerating] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isShipinfoLoading, setIsShipinfoLoading] = useState(false);
  const [isLoadingRestore, setIsLoadingRestore] = useState(false);
  // const[visible,setVisible]=useState(false)
  //  console.log("isVisibleArray2",isVisibleArray)
  const [loading, setLoading] = useState(false); // Add loading state
  const [maxPOApi, setmaxPOApi] = useState();
  // console.log(maxPOApi)

  // const handleCompanyShipmentInput = (e) => {
  //   //console.log('enabkle?', e.target.disabled)
  //   setCSN(e.target.value.toUpperCase());
  //   // console.log(company_shipment_num);
  //   //setIsBlocking(true);
  // };
  useEffect(() => {
    const checkNull = entry.csn === null ? "" : entry.csn;
    setCSN(checkNull);

    return () => {};
  }, []);

  const handleUpdate = () => {
    // for updating updatedDate row
    // console.log("is handle update calling");
    const updatedData = { updatedAt: new Date().toISOString() };
    // console.log("updated data date", updatedData);
    updateData(entry.ShipNo, updatedData);
  };

  const handleRestore = async (lerrosShipment) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to restore this shipment?"
    );
    if (!isConfirmed) return;
    setIsLoadingRestore(true);
    try {
      // setIsInputDisabled(true)
      // setCSN(null);
      const restoredData = await axios.get(
        `/csv/revertShipment?lerrosShip=${lerrosShipment}&factoryShip=${entry.ShipNo}`
      );
      // console.log('status', restoredData);
      setIsLoadingRestore(false);
      toast.remove();
      toast.success(restoredData.data.message);
      handleFilter(false, true, 0, 30);
      setIsInputDisabled(false);
      setCsvSent(false);
      setCsvUnsent(true);
      // sendToSent(factoryShipment);
      setCSN("");
    } catch (err) {
      console.log("error", err);
      setIsLoadingRestore(false);
    }
  };

  const getMultiPO = async (index, ShipNo) => {
    if (isVisibleArray[index]) {
      // If the clicked popup is already open, close it
      const updatedVisibility = [...isVisibleArray];
      updatedVisibility[index] = false; // Set the clicked row to false
      setIsVisibleArray(updatedVisibility);
      return; // Exit early since we don't need to fetch data
    }

    // Set visibility for only the clicked row and close all others
    const updatedVisibility = new Array(isVisibleArray.length).fill(false); // Set all values to false
    updatedVisibility[index] = true; // Set the clicked row to true
    setIsVisibleArray(updatedVisibility);

    setLoading(true); // Start loading

    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(
        `${process.env.REACT_APP_API_LINK}/api/v1/barcode/getdistPOByFactoryShip?factShip=${ShipNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setmaxPOApi(res.data); // Set the received data
    } catch (error) {
      console.error(
        "Error during the API call:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false); // Stop loading once the response is received
    }
  };

  return (
    <>
      {/* {console.log("entryy", entry)} */}
      <tr className="table-active">
        <td>{index + 1}</td>
        <td>{entry.username}</td>
        <td>
          <div id="max_min" key={index}>
            <div>{entry.ShipNo}</div>
              <div
                id="info_icon"
                className="hover_container_1"
                onClick={() => getMultiPO(index, entry.ShipNo)}
              >
                <MdInfoOutline />
                {isVisibleArray[index] && (
                  <div className="maxPO_div" ref={popupRef}>
                    <div id="inside_popup_maxPO">
                      {loading ? (
                        <p id="loading">
                          <Spinner animation="border" size="sm" />
                        </p>
                      ) : maxPOApi && maxPOApi.length > 0 ? (
                        <div
                          id={
                            maxPOApi.length > 5
                              ? "inside_popup_maxPO1"
                              : "inside_popup_maxPO"
                          }
                        >
                          {maxPOApi.map((items, i) => (
                            <div id="max_PO_Data" key={i}>
                              PO:-{items}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p id="not_assigned">Not assigned..</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
          </div>
        </td>
        <td
          className="table-cell"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "8vh",
            border: "none",
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <input
              className={`form-control`}
              type="text"
              name="shipmentInput"
              style={{ marginRight: "20px" }}
              value={companyShipmentNo}
              onChange={(e) => {
                const value = e.target.value.toUpperCase().replace(/\D/g, ""); // Remove spaces
                setCSN(value);
              }}
              placeholder="Min 5 byte "
              minLength={5}
              maxLength={8}
              disabled={isInputDisabled}
              autoComplete="off"
            />
            {isInputDisabled ? (
              <>
                <button
                  className={`btn ${isInputDisabled ? "btn-primary" : ""}`}
                  onClick={() => {
                    setIsInputDisabled(false);
                  }}
                >
                  Edit
                </button>
                <button
                  className="ms-1 btn btn-danger"
                  // style={{width: '50px'}}
                  onClick={() => {
                    handleRestore(companyShipmentNo);
                  }}
                  disabled={isLoadingRestore}
                >
                  Revert
                </button>
              </>
            ) : null}
          </div>
        </td>
        <td>{convertTimeToCountry(entry.createdAt)}</td>
        <td>{convertTimeToCountry(entry.updatedAt)}</td>
        <td>
          <button
            className="btn btn-none"
            onClick={async () => {
              // console.log('compnay shipment number', companyShipmentNo)
              // console.log("csv sent status", csvSent);
              // console.log('csn', entry.csn);
              // console.log("is input desible", isInputDisabled);

              if (csvSent === true && entry.csn !== null && isInputDisabled) {
                toast.remove();
                return ErrosToast(
                  "CSV/ShipCsv can not be downloaded, you need to edit it first"
                );
              }
              if (companyShipmentNo === "" || companyShipmentNo.length < 5) {
                // console.log(
                //   `entryyyyyyyyyyyyyyyy  : ${entry.csn}  : ${companyShipmentNo}`
                // );
                toast.remove();
                return ErrosToast(
                  "Lerros shipment should be at least 5 characters"
                );
              }
              if (companyShipmentNo == entry.csn) {
                toast.remove();
                return ErrosToast(
                  "Change the lerros shipment to download new Csv/ShipCsv"
                );
              } else {
                try {
                  //setIsBlocking(false)
                  setIsCsvLoading(true);
                  await axios
                    .get(`/csv/convert`, {
                      params: {
                        shipNo: entry.ShipNo,
                        csn: companyShipmentNo,
                        modifyCsn: company_shipment_num,
                        timezone: localStorage.getItem("timezone"),
                      },
                    })
                    .then((res) => {
                      // console.log("res", res);

                      if (csvSent == false && csvUnsent == true) {
                        sendToSent(entry.ShipNo);
                        setCSN("");
                      } else {
                        setCSN(companyShipmentNo);
                        setIsInputDisabled(true);
                      }
                      setIsUpdateShipmentNo((prev) => !prev);
                      setTimeout(() => {
                        window.location.replace(
                          process.env.REACT_APP_API_LINK +
                            "/api/v1/csv/download?oneTimeToken=" +
                            res.data.downloadTokens[0]
                        );
                      }, 1000);
                      window.location.replace(
                        process.env.REACT_APP_API_LINK +
                          "/api/v1/csv/download?oneTimeToken=" +
                          res.data.downloadTokens[1]
                      );

                      // setIsCsvLoading(false);
                      handleUpdate();
                      // console.log("in", isInputDisabled);
                    });
                } catch (err) {
                  // console.log(err);
                  setIsCsvLoading(false);
                  if (
                    err.response.data ===
                    "Company Shipment Number Already exists"
                  ) {
                    alert(" Given Company Shipment Number Already exists");
                    setIsCsvLoading(false);
                  } else alert("Something went wrong!");
                } finally {
                  setIsCsvLoading(false);
                }
                // setCsvSent(false);
                // setCsvUnsent(true);
                //handleNavigation();
              }
            }}
            disabled={isCsvLoading}
          >
            {isCsvLoading ? (
              <div className="spinner-border">
                <span className="visually-hidden">Loading..</span>
              </div>
            ) : (
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ultraviolet/40/import-csv.png"
                alt="import-csv"
              />
            )}
          </button>
        </td>

        <td>
          <button
            className="btn btn-none"
            onClick={() => {
              setIsExcelLoading(true);
              axios
                .get(
                  `${process.env.REACT_APP_API_LINK}/api/v1/csv/summary?shipNo=${entry.ShipNo}&csn=${companyShipmentNo}`,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res) => {
                  window.location.replace(
                    process.env.REACT_APP_API_LINK +
                      "/api/v1/csv/download?oneTimeToken=" +
                      res.data.downloadToken
                  );
                  setIsExcelLoading(false);
                })
                .catch((err) => {
                  setIsExcelLoading(false);
                  // console.log(err);
                  alert("No entry found");
                });
            }}
            disabled={isExcelLoading}
          >
            {isExcelLoading ? (
              <div className="spinner-border">
                <span className="visually-hidden">Loading..</span>
              </div>
            ) : (
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/export-excel.png"
                alt="export-excel"
              />
            )}
          </button>
        </td>
        {/* <td>
            <button
              className="btn btn-none"
              onClick={() => {
                setIsShipinfoLoading(true);
  
                axios
                  .get(`/shipInfo/convert`, {
                    params: {
                      shipNo: entry.ShipNo,
  
                      shipGroup: companyShipmentNo,
                    },
                  })
                  .then((res) => {
                    window.location.replace(
                      process.env.REACT_APP_API_LINK +
                        "/api/v1/shipInfo/download?oneTimeToken=" +
                        res.data.downloadToken
                    );
                    console.log("result hai kya", res);
                    setIsShipinfoLoading(false);
                  })
                  .catch((err) => {
                    setIsShipinfoLoading(false);
                    console.log(err);
                  });
  
                //handleNavigation();
              }}
            >
              {isShipinfoLoading ? (
                <div className="spinner-border">
                  <span className="visually-hidden">Loading..</span>
                </div>
              ) : (
                <img
                  width="54"
                  height="54"
                  src="https://img.icons8.com/ultraviolet/40/import-csv.png"
                  alt="import-csv"
                />
              )}
            </button>
          </td> */}
      </tr>
    </>
  );
};

export default CsvRow;
