import React, { useCallback, useContext } from "react";
import _, { debounce } from "underscore";
import "./BarcodePdf.css";
import Header from "../../components/header";
import { Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
// import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import Context from "../../context/context";
import toast from "react-hot-toast";
import ErrosToast from "../../components/ErrosToast";
import Swal from "sweetalert2";
const MAX_RETRIES = 10;

const BarcodePdf = () => {
  const [entryByPOdata, setEntryByPOdata] = useState([]); // to store the data of api /entry/getAllByPO
  const [filteredEntryByPOdata, setFilteredEntryByPOdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false); //
  const [sentCheckBox, setSentCheckBox] = useState(false);
  const [unsentCheckBox, setUnsentCheckBox] = useState(true);

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const [poSearch, setPoSearch] = useState("");
  const [selectedStickerValue, setSelectedStickerValue] = useState("ALL");
  const [customerSearch, setCustomerSearch] = useState("");
  const [lShimpentSearch, setlShimpentSearch] = useState("");
  const [type, setType] = useState("");
  // console.log("typ", type);
  // console.log("lerros shipment", lShimpentSearch);
  const ctx = useContext(Context);
  const new_array = ctx.userData.leftBarcode.split("");
  // console.log("line30", new_array.length);

  const name = ctx.userData.username;
  // console.log("ctx==>>>line 30", name);
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const PO = queryParams.get("PO");

  // console.log("newww", PO);
  // console.log("rangeApi", ctx.rangeApi);

  const fetchData = async (
    sentCheckBox,
    unsentCheckBox,
    pageNo,
    limit,
    poSearch,
    selectedStickerValue,
    customerSearch,
    lShimpentSearch,
    type
  ) => {
    setIsLoading(true);
    try {
      // if(selectedStickerValue === undefined){
      //   selectedStickerValue= ""
      // }
      const res = await axios.get(
        `/entry/getAllByPO?sent=${sentCheckBox}&unsent=${unsentCheckBox}&pageNo=${pageNo}&docNo=${limit}&po=${poSearch}&cust=${customerSearch}&lerrosShip=${lShimpentSearch}&typFilter=${type}
`
      );
      // console.log("selected sticker value", selectedStickerValue);
      if (selectedStickerValue === "ALL") {
        setEntryByPOdata(res.data.entries);
      } else {
        const filteredData = res.data.entries.filter(
          (data) =>
            data.STYP ===
            (selectedStickerValue === "LERROS" ? "" : selectedStickerValue)
        );
        setFilteredEntryByPOdata(filteredData);
        setEntryByPOdata(filteredData);
      }

      setPage(pageNo);
      setInitialFetchDone(true);
      if (res.data.entries.length < limit) {
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (
        poSearch,
        customerSearch,
        lShimpentSearch,
        type,
        sentCheckBox,
        unsentCheckBox
      ) =>
        fetchData(
          sentCheckBox,
          unsentCheckBox,
          0,
          1000,
          poSearch,
          selectedStickerValue,
          customerSearch,
          lShimpentSearch,
          type
        ),
      1000,
      false
    ),
    [selectedStickerValue]
  );

  useEffect(() => {
    // fetchData(0, 30)
    // console.log("am i called?");
    if (!initialFetchDone) {
      fetchData(
        sentCheckBox,
        unsentCheckBox,
        0,
        1000,
        poSearch,
        selectedStickerValue,
        customerSearch,
        lShimpentSearch,
        type
      );
      // Set initialFetchDone to true after initial fetch
      return;
    }

    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        // console.log("boxapi length", entryByPOdata.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !isLoading
        ) {
          fetchData(
            sentCheckBox,
            unsentCheckBox,
            0,
            30 * ((entryByPOdata.length + 30) / 30),
            poSearch,
            selectedStickerValue,
            customerSearch,
            lShimpentSearch,
            type
          );
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page, isLoading]);

  // const generateBarcodes = async (PO) => {
  //   // console.log("clicked");
  //   await axios
  //     .post("/barcode/generate", {
  //       PO: PO,
  //     })
  //     .then((res) => {
  //       // if (res.status === 200) {
  //       //   navigate("/exFactory");
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   navigate("/exFactory");
  // };

  const BarcodeButton = ({ PO, BoxGroup, user_to_use = null }) => {
    const [generating, setGenerating] = useState(false);
    async function generateBarcode(retries = 0) {
      setGenerating(true);
      const checkBoxGroup =
        BoxGroup === null || BoxGroup === undefined || BoxGroup === ""
          ? ""
          : BoxGroup;
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_LINK}/api/v1/csv/generateBarcodes?PO=${PO}&BoxGroup=${checkBoxGroup}&user_to_use=${user_to_use}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        window.location.assign(
          `${process.env.REACT_APP_API_LINK}/api/v1/csv/download?oneTimeToken=${res.data.downloadToken}`
        );

        try {
          const profileRes = await axios.get("/auth/getProfile");
          ctx.setUserData(profileRes.data.user);
        } catch (err) {
          console.log(err);
        }

        toast.remove();
        if (BoxGroup !== null) {
          toast.success("Barcode range re-printed successfully");
        } else {
          toast.success("Barcode generated successfully");
        }
        setGenerating(false);
        setSentCheckBox(true);
        setUnsentCheckBox(false);
        setHasMore(true);
        fetchData(
          true,
          false,
          0,
          1000,
          poSearch,
          selectedStickerValue,
          customerSearch,
          lShimpentSearch,
          type
        );
        console.log("ctx po data length", ctx.poData.length);
        if (ctx.poData.length > 0) {
          const lockedData = await axios.post(
            process.env.REACT_APP_API_LINK +
              "/api/v1/po/getLockedPurchaseOrders",
            {
              POIDs: ctx.selectedCheckboxes,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          ctx.setLastBCGeneratedSNo(lockedData.data.lastBCGeneratedSNo);
          ctx.setLastTo(lockedData.data.lastValueOfTo);
        }
      } catch (err) {
        const status = _.get(err, ["response", "status"], 0);

        if (status === 429 && retries < MAX_RETRIES) {
          console.log(
            `Too many requests. Retrying in 5 seconds... (${
              retries + 1
            }/${MAX_RETRIES})`
          );
          setTimeout(() => generateBarcode(retries + 1), 5000);
        } else {
          setGenerating(false);
          console.log(err);
          toast.remove();
          ErrosToast(_.get(err, "response.data.message", "An error occurred"));
        }
      }
    }
    // console.log("po data", ctx.poSummary);

    // const DownloadBarcodePdf = async () => {
    //   // if (ctx.userData.leftBarcode <= 1000) {
    //   //   alert("Left Nve Range is less than 1000, Do you want to continue");
    //   // }
    //   // const checkPOqty = ctx.poSummary.find((poData) => poData.PO === PO)
    //   // console.log('check po qwtu', checkPOqty)

    //   let alertCheck = false;
    //   const hasBoxGroup = BoxGroup && BoxGroup.trim();

    //   if (!hasBoxGroup) {
    //     alertCheck = window.confirm(
    //       `If you generate a barcode PDF for ${PO}, the next box group's sequence will start from 1. Do you want to proceed?`
    //     );
    //     // toast.success("Barcode generated successfully");
    //     // Proceed only if the user confirms
    //     if (alertCheck) {
    //       await generateBarcode();
    //     } else {
    //       setGenerating(false);
    //     }
    //   } else {
    //     await generateBarcode();
    //   }
    // };

    const DownloadBarcodePdf = async () => {
      let alertCheck = false;
      const hasBoxGroup = BoxGroup && BoxGroup.trim();

      if (!hasBoxGroup) {
        const result = await Swal.fire({
          html: `If you generate a barcode PDF for <strong>${PO}</strong>, the next box group's sequence will start from 1. Do you want to proceed?`,
          showCancelButton: true,
          confirmButtonText: "Yes, proceed",
          cancelButtonText: "Cancel",
          position: "center",
          customClass: {
            popup: "custom-swal-popup", // Custom class for the popup
            confirmButton: "custom-confirm-button", // Custom class for the OK button
            cancelButton: "custom-cancel-button", // Custom class for the Cancel button
          },
        });

        if (result.isConfirmed) {
          // If the user clicks "Yes, proceed"
          alertCheck = true;
          await generateBarcode(); // Call the function to generate the barcode
        } else {
          // If the user clicks "Cancel"
          setGenerating(false); // Optional: Reset the generating state
        }
      } else {
        // If `BoxGroup` is not empty
        await generateBarcode();
      }
    };

    return (
      <button
        className="btn btn-none "
        onClick={() => {
          DownloadBarcodePdf();
        }}
        disabled={generating}
      >
        {generating ? (
          <Spinner size="lg" />
        ) : (
          <img
            width="37"
            height="37"
            src="https://img.icons8.com/cotton/64/barcode-scanner--v3.png"
            alt="barcode-scanner--v3"
          />
        )}
      </button>
    );
  };
  const ExcelButton = ({ PO, STY, BoxGroup }) => {
    const [generating, setGenerating] = useState(false);

    const DownloadExcel = () => {
      setGenerating(true);
      axios
        .get(`/csv/poSummaryExcels`, {
          params: {
            PO,
            STY,
            BoxGroup,
            timezone: localStorage.getItem("timezone"),
          },
        })
        .then((res) => {
          // setTimeout(() => {
          //   window.location.replace(
          //     process.env.REACT_APP_API_LINK +
          //     "/api/v1/csv/download?oneTimeToken=" +
          //     res.data.downloadTokens[0]
          //   );
          // }, 1000);
          window.location.replace(
            process.env.REACT_APP_API_LINK +
              "/api/v1/csv/download?oneTimeToken=" +
              res.data.downloadTokens[0]
          );
          setGenerating(false);
        })
        .catch((err) => {
          console.log(err);
          setGenerating(false);
          alert("Something went wrong!");
        });
    };

    return (
      <button
        className="btn btn-none "
        onClick={() => {
          DownloadExcel();
        }}
        disabled={generating}
      >
        {generating ? (
          <Spinner size="lg" />
        ) : (
          <img
            width="37"
            height="37"
            src="https://img.icons8.com/color/48/export-excel.png"
            alt="barcode-scanner--v4"
          />
        )}
      </button>
    );
  };

  const onChangeStickerOptions = async (event) => {
    // console.log("option", event.target.value);
    setSelectedStickerValue(event.target.value);
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/entry/getAllByPO?sent=${sentCheckBox}&unsent=${unsentCheckBox}&pageNo=${page}&docNo=${1000}&po=${poSearch}&cust=${customerSearch}&lerrosShip=${lShimpentSearch}&typFilter=${type}`
      );
      let value = event.target.value;
      if (event.target.value === "LERROS") {
        value = "";
      }

      const filteredData = res.data.entries.filter(
        (data) => data.STYP === value
      );
      // console.log('filter data', filteredData)
      if (value === "ALL") {
        setEntryByPOdata(res.data.entries);
        setFilteredEntryByPOdata(res.data.entries);
      } else {
        setEntryByPOdata(filteredData);
        setFilteredEntryByPOdata(filteredData);
      }
      // if(res.data.entries.length > 0){
      //   const filteredType = res.data.STYPData.filter((data) => data.maxSTYP === value);
      //   const filteredPOs = filteredType.map((data) => data.PO);
      //   const allData = res.data.entries.filter((data) => filteredPOs.includes(data.PO))

      //   if(event.target.value == "ALL"){
      //     setEntryByPOdata(res.data.entries)
      //   }
      //   else{
      //     setEntryByPOdata(allData)
      //     setFilteredEntryByPOdata(allData)
      //   }
      // }
      // else{
      //   setFilteredEntryByPOdata([])
      // }
      // else{
      //   setEntryByPOdata(res.data.entries);
      // }
      // setIsLoading(false);
      // setPage(50);
      setInitialFetchDone(true);
      // if (res.data.entries.length < limit) {
      //   setHasMore(false);
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="fixed-top">
        <Header />
        <div
          style={{
            flexDirection: unsentCheckBox ? "row" : "row",
          }}
          id={name === "admin" ? "main_" : "main_1"}
        >
          <div
            style={{
              width: unsentCheckBox ? "93%" : "83%",
            }}
            id={name === "admin" ? "main_Container1" : "main_Container"}
          >
            <div
              id="open"
              className="container_1"
              style={{
                // border: "2px solid red",
                width: "76px",
              }}
            >
              <Form.Check
                type="checkbox"
                value={unsentCheckBox}
                checked={unsentCheckBox}
                onChange={(e) => {
                  setUnsentCheckBox((prev) => !prev);
                  setHasMore(true);
                  fetchData(
                    sentCheckBox,
                    e.target.checked,
                    0,
                    1000,
                    poSearch,
                    selectedStickerValue,
                    customerSearch,
                    lShimpentSearch,
                    type
                  );
                }}
                label="Open"
              />
            </div>
            <div
              id="ex_factory"
              className="container_2"
              style={{
                // border: "2px solid green",
                width: "115px",
              }}
            >
              <Form.Check
                type="checkbox"
                checked={sentCheckBox}
                value={sentCheckBox}
                onChange={(e) => {
                  setSentCheckBox((prev) => !prev);
                  setHasMore(true);
                  fetchData(
                    e.target.checked,
                    unsentCheckBox,
                    0,
                    1000,
                    poSearch,
                    selectedStickerValue,
                    customerSearch,
                    lShimpentSearch,
                    type
                  );
                }}
                className="ms-3"
                label="Ex Factory"
              />
            </div>
            <div
              className="container_3"
              id="nve_range"
              style={{
                display: name === "admin" || !unsentCheckBox ? "none" : "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                // border: "2px solid red",
                width: new_array.length < 4 ? "fit-content" : "14%",
              }}
            >
              {ctx.userData.admin === 0 && unsentCheckBox && (
                <div
                  style={{
                    display: "flex",
                    // border: ".5px solid blue",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    fontSize: "14px",
                    flexDirection: "column",
                  }}
                >
                  <div id="conatiner_5_p" style={{ width: "126px" }}>
                    Left NVE Range:
                  </div>
                  <div style={{ paddingLeft: "4%" }}>
                    {" "}
                    {ctx.userData.leftBarcode}
                  </div>
                </div>
              )}
            </div>
            <div
              className="container_4"
              style={{
                // border: ".5px solid red",
                width: "fit-content 172px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                // width: "144px",
                width: "fit-content",
                flexDirection: "column",
              }}
            >
              <div
                className="fs-6 me-2"
                style={{
                  width: "auto",
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                PO#
              </div>
              <div>
                <Form.Control
                  id="po_input"
                  type="text"
                  name="po"
                  maxLength={6}
                  style={{
                    width: "105px",
                    height: "5vh",
                    border: "2px solid black",
                  }}
                  value={poSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission on Enter
                    }
                  }}
                  onChange={(e) => {
                    setPoSearch(
                      e.target.value.replace(/\D/g, "").toUpperCase()
                    );
                    OnChangeHandleFilter(
                      e.target.value.replace(/\D/g, "").toUpperCase(),
                      customerSearch,
                      lShimpentSearch,
                      type,
                      sentCheckBox,
                      unsentCheckBox,
                      selectedStickerValue
                    );
                    // poSearch,
                    //   customerSearch,
                    //   lShimpentSearch,
                    //   sentCheckBox,
                    //   unsentCheckBox;
                  }}
                  placeholder="PO"
                  autoComplete="off"
                />
              </div>
            </div>

            <div
              className="conatiner_5"
              style={{
                // border: ".5px solid blue",
                width: "fit-content 217px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "start",
                // width: "200px",
                width: "fit-content",
                flexDirection: "column",
              }}
            >
              <div
                className="fs-6 me-2"
                style={{
                  width: "auto",
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Customer
              </div>
              <Form.Control
                id="customer_input"
                type="text"
                name="po"
                maxLength={10}
                style={{
                  height: "5vh",
                  border: "2px solid black",
                  width: "110px",
                }}
                value={customerSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission on Enter
                  }
                }}
                onChange={(e) => {
                  setCustomerSearch(e.target.value.replace(/\D/g, ""));
                  setCustomerSearch(e.target.value.replace(/\D/g, ""));
                  OnChangeHandleFilter(
                    poSearch,
                    e.target.value.replace(/\D/g, "").toUpperCase(),
                    lShimpentSearch,
                    type,
                    sentCheckBox,
                    unsentCheckBox,
                    selectedStickerValue
                  );
                }}
                placeholder="Customer"
                autoComplete="off"
              />
            </div>

            <div className="container_7">
              <div
                id="LerrosShipment"
                // className="fs-6 me-2"
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: "600",
                  width: "114px",
                  // border: "2px solid green",
                }}
              >
                Lerros Shipment
              </div>
              <div>
                <Form.Control
                  id="lerros_shipment_input"
                  type="text"
                  name="po"
                  maxLength={8}
                  style={{
                    width: "114px",
                    height: "5vh",
                    border: "2px solid black",
                    // border: "none",
                  }}
                  value={lShimpentSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission on Enter
                    }
                  }}
                  onChange={(e) => {
                    setlShimpentSearch(e.target.value.replace(/\D/g, ""));
                    setlShimpentSearch(e.target.value.replace(/\D/g, ""));
                    OnChangeHandleFilter(
                      poSearch,
                      customerSearch,
                      e.target.value.replace(/\D/g, "").toUpperCase(),
                      type,
                      sentCheckBox,
                      unsentCheckBox,
                      selectedStickerValue
                    );
                  }}
                  placeholder="Lerros Shipment"
                  autoComplete="off"
                />
              </div>
            </div>

            <div
              className="container_7"
              id="main_po_type"
              style={{
                // border: ".5px solid red",
                // width: "155px",
                width: "fit-content",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "start ",
                flexDirection: "column",
                paddingLeft: "1px",
              }}
            >
              <div
                id="po_type"
                // className="fs-6 me-2"
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: "600",
                  width: "80px",
                  // border: "2px solid green",
                  paddingLeft: "4%",
                }}
              >
                PO# Type
              </div>
              <div>
                <Form.Control
                  id="po_type_input"
                  type="text"
                  name="type"
                  maxLength={1}
                  style={{
                    width: "114px",
                    height: "5vh",
                    border: "2px solid black",
                  }}
                  value={type}
                  onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    setType(value.match(/^[A-Z]$/) ? value : "");
                    OnChangeHandleFilter(
                      poSearch,
                      customerSearch,
                      lShimpentSearch,
                      value,
                      sentCheckBox,
                      unsentCheckBox,
                      selectedStickerValue
                    );
                  }}
                  placeholder="PO# Type"
                  autoComplete="off"
                />
              </div>
            </div>
            <div
              // style={{
              //   padding: unsentCheckBox ? "0.5% 0% 0.5% 4%" : "0% 0% 0% 1%",
              // }}
              id={
                name === "admin" ? "second_main_header" : "second_main_header1"
              }
            >
              <div
                className="conatiner_6"
                style={{
                  // border: "2px solid green",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "start",
                  width: "170px",
                  flexDirection: "column",
                }}
              >
                <div id="conatiner_5_p" style={{ paddingRight: "0% 2% 0% 4%" }}>
                  Sticker
                </div>
                <div>
                  <select
                    id="dropdown_select"
                    style={{
                      border: "2px solid black",
                      height: "5vh",
                      padding: "2% 0%",
                      borderRadius: ".4em",
                      fontSize: "13px",
                      color: "black",
                      width: "96px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "300",
                    }}
                    onChange={onChangeStickerOptions}
                    value={selectedStickerValue}
                  >
                    <option style={{ fontSize: "14px" }} value={"ALL"}>
                      Select Sticker
                    </option>
                    {/* <option style={{ fontSize: "14px" }}>
                  Lerros/Div.07 Key Account/AMG/Carl Gross/Breuninger
                </option> */}
                    <option style={{ fontSize: "14px" }} value={"LERROS"}>
                      Lerros
                    </option>
                    <option style={{ fontSize: "14px" }} value={"MOGSTKOTTO"}>
                      Otto
                    </option>
                    <option style={{ fontSize: "14px" }} value={"MOGSTKFUSS"}>
                      Fussl
                    </option>
                    <option style={{ fontSize: "14px" }} value={"MOGSTKFAMI"}>
                      Familia
                    </option>
                    <option style={{ fontSize: "14px" }} value={"MOGSTKGALI"}>
                      Galileo
                    </option>
                    {/* {/ <option style={{ fontSize: "14px" }} value={"MOGSTKHSE"}> /}
                  {/ HSE /}
                  / </option> / */}
                    <option style={{ fontSize: "14px" }} value={"MOGSTKJIMS"}>
                      Jim Spencer
                    </option>
                    <option style={{ fontSize: "14px" }} value={"MOGSTKAMCB"}>
                      AMG
                    </option>
                    {/* {/ <option style={{ fontSize: "14px" }} value={"MOGSTKKAUF"}> /}
                  {/ Kaufland /}
                  {/ </option> /} */}
                    {/* {/ <option style={{ fontSize: "14px" }} value={"MOGSTKTECH"}> /}
                  {/ Techno/PINX /}
                  {/ </option> /} */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: unsentCheckBox ? "170px" : "164px" }}
        id={name === "admin" ? "table_main_admin" : "table_main_user"}
        className=" mx-4 tableFixHead table-container"
        // style={{
        //   marginTop: name === "admin" ? "165px" : "175px",
        //   marginBottom: "21px",
        //   height: name === "admin" ? "70vh" : "66vh",

        // }}
      >
        {isLoading && !initialFetchDone ? (
          <Loader />
        ) : (
          <div className="table-responsive mx-4 tableFixHead table-container">
            <table className="table table-bordered table-hover table-sm mt-4 table-header-for-all">
              <thead>
                <tr className="table-warning">
                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      S.No.
                    </div>
                  </th>
                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      PO#
                    </div>
                  </th>
                  <th style={{ width: "8%", borderTop: "1px solid black" }}>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      Box Group
                    </div>
                  </th>
                  <th style={{ borderRight: "1px solid black" }}>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      Style
                    </div>
                  </th>

                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      Factory
                    </div>
                  </th>
                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      User
                    </div>
                  </th>
                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      Sticker
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        border: "2px solid #fff3cd",
                        width: "130px",
                        padding: "1%",
                      }}
                    >
                      Lerros Shipment
                    </div>
                  </th>
                  <th style={{ width: "7%" }}>
                    {" "}
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      PO# Type
                    </div>
                  </th>
                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      Customer
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        border: "2px solid fff3cd",
                        padding: "1%",
                        width: "140px",
                      }}
                    >
                      Number Of Boxes
                    </div>
                  </th>
                  {/* {ctx.userData.admin === 0 && <th>GenerateBarcode</th>} */}
                  {ctx.userData.admin === 1 ? (
                    <th>
                      <div
                        style={{ border: "2px solid #fff3cd", padding: "1%" }}
                      >
                        Print PDF
                      </div>
                    </th>
                  ) : (
                    <th>
                      <div
                        style={{ border: "2px solid #fff3cd", padding: "1%" }}
                      >
                        Generate/Print
                      </div>
                    </th>
                  )}

                  {/* <th>Print PDF</th> */}
                  <th>
                    <div style={{ border: "2px solid #fff3cd", padding: "1%" }}>
                      Excel
                    </div>
                  </th>
                </tr>
              </thead>
              {entryByPOdata.length > 0 ? (
                <tbody>
                  {entryByPOdata.map((el, i) => {
                    // console.log("entry by po data", el);
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        {/* <Link to={`/exFactory?PO=${el.PO}`}>{el.PO}</Link> */}
                        <td>{el.PO}</td>
                        <td>{el.BoxGroup}</td>
                        <td>{el["entry.STY"]}</td>

                        <td>{el["entry.FACT"]}</td>
                        <td>{el["entry.username"]}</td>
                        <td>
                          {el.STYP === "" || !el.STYP ? "LERROS" : el.STYP}
                        </td>
                        <td>{el.ShipGroup}</td>
                        <td>{el.TYP}</td>
                        <td>{el.Customer}</td>

                        <td>{el.noOfBoxes}</td>
                        {/* {ctx.userData.admin === 0 && (

                      <td>
                        <button
                          onClick={() => generateBarcodes(el.PO)}
                          type="button"
                          className="btn btn-dark text-center"
                          style={{ width: "150px" }}
                        >
                          GenerateRange
                        </button>
                      </td>
                    )} */}
                        {ctx.userData.admin === 0 ? (
                          <td>
                            <BarcodeButton
                              key={el.PO}
                              PO={el.PO}
                              BoxGroup={el.BoxGroup}
                            />
                          </td>
                        ) : el.BoxGroup ? (
                          <td>
                            <BarcodeButton
                              key={el.PO}
                              PO={el.PO}
                              BoxGroup={el.BoxGroup}
                              user_to_use={el["entry.username"]}
                            />
                          </td>
                        ) : (
                          <td>Barcode isn’t generated yet</td>
                        )}
                        <td>
                          <ExcelButton
                            key={el.PO}
                            PO={el.PO}
                            STY={el["entry.STY"]}
                            BoxGroup={el.BoxGroup}
                          />
                          {/* <button className="btn btn-none">
                        <img
                          width="37"
                          height="37"
                          src="https://img.icons8.com/color/48/export-excel.png"
                          alt="export-excel"
                        />
                      </button> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="13"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <h5>NO DATA FOUND</h5>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>

            {/* {!isLoading && !hasMore && <div>No more data</div>} */}
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BarcodePdf;
