import React, { useContext, useEffect,  useState } from "react";
// import Headroom from "react-headroom";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import context from "../context/context";
import NotificationBadge from "./NotificationBatch";
import axios from "axios";
import Logo from "../logo_01.JPG";
import { Spinner } from "react-bootstrap";
import { useSocketContext } from "../context/socketContext";

function Header() {
  const navigate = useNavigate();
  const { userData } = useContext(context);
  const factoryString = userData.factory || "";
  const factoryLength = factoryString.split(",").length;
  // console.log("factoryLength",factoryLength)
  // const authName = userData;
  // console.log("line15", authName);
  const [isLoading, setIsLoading] = useState(false);
  const { disconnect } = useSocketContext();

  const location = useLocation();
  // console.log("location path name: ", location.pathname);

  // const scrollLeftRef  = useRef(null);

  // const [scrollX, setScrollX] = useState({
  //   side: ""
  // });
  // const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = (data) => {
  //   setScrollX(prev => ({ ...prev, side: data.side }));
  // }

  // useEffect(() => {
  //   console.log('getting or not', scrollLeftRef.current.scrollLeft)
  //   const container = scrollLeftRef.current;
  //   const scrollAmount = 200;
  //   if (scrollX.side === "right") {
  //     // console.log('right')

  //     container.scrollLeft += scrollAmount;
  //   } else {
  //     // console.log('left')
  //     container.scrollLeft -= scrollAmount;
  //   }
  // }, [scrollX]);

  //console.log('cureent scroll', scrollLeftRef.current.scrollLeft)

  // const handleScrollLeft = (direction) => {
  //   console.log('scrolling ', direction)
  //   const container = scrollLeftRef.current;
  //   const scrollAmount = 200; // Adjust as needed
  //   if (direction === 'left') {
  //     container.scrollLeft -= scrollAmount;
  //   } else {
  //     container.scrollLeft += scrollAmount;
  //   }
  //   setScrollPosition(container.scrollLeft);
  // };

  useEffect(() => {
    // console.log("userData", userData);
    const rightArrow = document.querySelector(
      ".scrollable-tabs-container .right-arrow button"
    );
    const leftArrow = document.querySelector(
      ".scrollable-tabs-container .left-arrow button"
    );
    const tabsList = document.querySelector(".scrollable-tabs-container ul");

    const handleRightArrowClick = () => {
      tabsList.scrollLeft += 200;
    };

    const handleLeftArrowClick = () => {
      tabsList.scrollLeft -= 200;
    };

    rightArrow.addEventListener("click", handleRightArrowClick);
    leftArrow.addEventListener("click", handleLeftArrowClick);

    const activeTab = document.querySelector(
      ".scrollable-tabs-container .nav-link.active"
    );
    if (activeTab) {
      activeTab.scrollIntoView({ behavior: "smooth", inline: "center" });
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      rightArrow.removeEventListener("click", handleRightArrowClick);
      leftArrow.removeEventListener("click", handleLeftArrowClick);
    };
  }, [location]); // Empty dependency array ensures that this effect runs only once after initial render

  // render your component
  // console.log("line218 ", userData.orders);
  return (
    <div>
      <nav className="navbar navbar-expand-lg sticky-top navbar-dark p-0">
        <div
          className="container-fluid px-0 text-bg-dark"
          style={{ fontSize: "1rem", fontWeight: "100" }}
        >
          <div title="Company Logo" to="/" className="navbar-brand ms-2">
            <img
              src={Logo}
              width="50"
              height="50"
              alt="Logo"
              className="border border-4 rounded"
            />
          </div>
          <div to="/" className="navbar-brand">
            <h4 title="Company Name" className="m-0 text-light">
              {userData.company || "Company"}
              {/* {alert("company")} */}
            </h4>
            <small title="Factory" className="me-1 ">
            {factoryLength > 1 ? (
  <>
    {factoryString.split(",").shift()}
    <span style={{fontSize:"22px", paddingLeft:"2%"}}>*</span>
  </>
) : (
  userData.factory || "FACT"
)}

              {/* {console.log("factory name>>>",(userData.factory).split(",").length)} */}
            </small>

            <small title="Supplier" className="ms-1">
              {userData?.supplyDetails?.CTY || "CTY"}
            </small>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse  border-none justify-content-center scrollable-tabs-container"
            id="navbarSupportedContent"
            style={{ red: "#88bfe3" }}
          >
            {/* <div className="header-content"  ref={scrollLeftRef}> */}
            <div className="left-arrow">
              <button
                id="slideRight"
                className="scroll-btn scroll-btn-left "
                style={{
                  // border: "2px solid red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "3rem",
                }}
              >
                &lt;
              </button>
            </div>
            <ul
              className="navbar-nav text-small nav-pills nav-justified content-between-scroll-bar"
              style={{ width: "90%" }}
            >
              {/* <li> Prev</li> */}
              {!!userData.loadPO && (
                <li>
                  <NavLink
                    // style={{ background: "black" }}
                    to="/LoadPos"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>LoadData</small>
                    <NotificationBadge />
                  </NavLink>
                </li>
              )}
              {
                <li>
                  <NavLink
                    // style={{ background: "black" }}
                    to="/PoSummary"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>PoSummary</small>
                  </NavLink>
                </li>
              }
              {!!userData.dsp && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/DisplayPurchase"
                    className="nav-link text-secondary text-white text-decoration-none"
                  >
                    <small style={{ fontWeight: "400" }}>PurchaseOrders</small>
                  </NavLink>
                </li>
              )}
              {!!userData.createUser && (
                <li>
                  <NavLink
                    // style={{ background: "black" }}
                    to="/userService"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>CreateUser</small>
                  </NavLink>
                </li>
              )}
              {!!userData.modifyUser && (
                <li>
                  <NavLink
                    // style={{ background: "black" }}
                    to="/ModifyUser"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>ModiFyUser</small>
                  </NavLink>
                </li>
              )}
              {/* {!!userData.userPanel && (
                  <li>
                    <NavLink
                      // style={{ background: "black" }}
                      to="/UserPanel"
                      className="nav-link text-secondary text-white text-decoration-bold"
                    >
                      UserPanel
                    </NavLink>
                  </li>
                )} */}
              {/* !! convert 0/1 value into BOOLEAN because sql does not work with boolean */}
              {(userData.entry === true || userData.entry === 1) && (
                <li>
                  <NavLink
                    to="/orders"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>BoxEntries</small>
                  </NavLink>
                </li>
              )}
              {!!userData.boxDetails && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // activeClassName="active"
                    // style={{ background: "black" }}
                    to="/boxDetails"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>BoxDetails</small>
                  </NavLink>
                </li>
              )}
              {!!userData.barcodePdf && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/barcodePdf"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>BarcodePdf</small>
                  </NavLink>
                </li>
              )}
              {!!userData.exFactory && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/exFactory"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>ExFactory</small>
                  </NavLink>
                </li>
              )}
              {!!userData.shipInfo && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // activeClassName="active"
                    // style={{ background: "black" }}
                    to="/ShipInfo"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>ShipDetails</small>
                  </NavLink>
                </li>
              )}
              {!!userData.boxDetails && (
                <li>
                  <NavLink
                    to="/shipmentLoading"
                    className={
                      "nav-link text-secondary text-white text-decoration-bold"
                    }
                  >
                    <small style={{ fontWeight: "400" }}>ShipmentLoading</small>
                  </NavLink>
                </li>
              )}
              {!!userData.csv && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/csv"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>
                      TransferData/Print
                    </small>
                  </NavLink>
                </li>
              )}
              {!!userData.boxType && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/boxType"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>BoxType</small>
                  </NavLink>
                </li>
              )}
              {!!userData.rangeCode && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/rangeCode"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>RangeCode</small>
                  </NavLink>
                </li>
              )}
              {/* {!!userData.others && (
                <li>
                  <NavLink
                    // activeStyle={{ color: "#5754a8" }}
                    // style={{ background: "black" }}
                    to="/Others"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>Others</small>
                  </NavLink>
                </li>
              )} */}
              {!!userData.reports && (
                <li>
                  <NavLink
                    to="/reports"
                    className="nav-link text-secondary text-white text-decoration-bold"
                  >
                    <small style={{ fontWeight: "400" }}>Reports</small>
                  </NavLink>
                </li>
              )}
              {/* <li style={{ position: "fixed", float: "right" }}>Next</li> */}
            </ul>
            <div className="right-arrow">
              <button
                className="scroll-btn scroll-btn-right"
                id="slideRight"
                style={{
                  // border: "2px solid red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "3rem",
                }}
              >
                &gt;
              </button>
            </div>
          </div>

          <div className="fs-5 px-3 text-light ">
            <div>
              <span
                title="User Name"
                className="text-center"
                style={{ fontSize: "18px", textTransform: "capitalize" }}
              >
                <span className="pe-1 ">
                  <i className="fas fa-user "></i>
                </span>
                {userData.username || "username"}
              </span>
            </div>

            <div className="row ps-2">
              <button
                type="button"
                // style={{ border: "2px solid red" }}
                className="btn btn-danger text-light btn-sm pr-0 mb-2"
                onClick={async () => {
                  setIsLoading(true);
                  await axios
                    .get("/auth/logout")
                    .then(() => {
                      localStorage.clear();
                      setIsLoading(false);
                      disconnect();
                      navigate("/");
                    })
                    .catch((err) => {
                      // console.log(err);
                      alert("Something went wrong!");
                    });
                  setIsLoading(false);
                }}
              >
                {isLoading ? <Spinner size="sm" /> : "Logout"}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
