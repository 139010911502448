import { useEffect, useState } from "react";
// import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./PoFileLoadingStatus.css";
import Modal from "react-bootstrap/Modal";

function CustomSpinner() {
  return (
    <Spinner animation="border" role="status" id="spinner">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

function PoFileLoadingStatus() {
  const [loading, setLoading] = useState(true);
  const [remaining_file_count, setRemainingFilesCount] = useState(0);

  const [viewModal, setViewModal] = useState(false);
  const [viewDeleteModal, setViewDeleteModal] = useState(false);
  const [POLogsData, setPOLogsData] = useState([]);
  const [deletePO,setdeletePO]=useState()
  // console.log("deletePOApi",deletePO)

  useEffect(() => {
    async function fetch_data() {
      await fetchCurrentFileLoadingStatus();
    }
    fetch_data();
  }, []);

  const fetchCurrentFileLoadingStatus = async () => {
    setLoading(true);
    const res = await axios.get("/admin/getCurrentPoFileLoadingStatus");
    // console.log(res)
    const file_count = res.data.remaining_files;
    setRemainingFilesCount(file_count);
    setLoading(false);
  };

  const refreshHandler = async () => {
    await fetchCurrentFileLoadingStatus();
  };

  const handlePOHistory = async () => {
    setPOLogsData([]); // Clear old data to prevent showing stale data
    setViewModal(true); // Open the modal
    
    try {
      const poLogs = await axios.get(
        process.env.REACT_APP_API_LINK + "/api/v1/admin/getPoFileLoadLogs"
      );
      setPOLogsData(poLogs.data.data.reverse());
    } catch (error) {
      console.error("Error fetching PO logs:", error);
    }
  };

  const handlePODelete = async () => {
   
    setViewDeleteModal(true);
    const api = `${process.env.REACT_APP_API_LINK}/api/v1/admin/getPoFileDeleteLogs`;
    
    try {
        const res = await axios.get(api, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        setdeletePO(res.data)
        // console.log("API Response:", res.data);
    } catch (error) {
        console.error("API is not working", error);
    }
};


  const handleClose = () => setViewModal(false);
  // const handleDeleteClose = () => setViewDeleteModal(false);
  return (
    <div id="main">
      <div id="container_">
        <div>File loading status</div>
        <div id="Spinner">
          {loading ? <CustomSpinner /> : remaining_file_count + " remaining.."}
        </div>
        <Navbar.Toggle />
      </div>
      <div className="justify-content-end" id="btn_both">
        <Button variant="success" onClick={refreshHandler}>
          Refresh
        </Button>
        <Button variant="primary" onClick={handlePOHistory}  style={{width:"fit-content"}}>
          PO History
        </Button>
        <Button variant="primary" onClick={handlePODelete} style={{width:"fit-content"}}>
          Delete PO History
        </Button>


            {/* History btn Modal */}
        <Modal
        className="deleteModal"
          show={viewModal}
          onHide={handleClose}
          animation={false}
          scrollable={true}
        >
          <Modal.Header closeButton className="dleteHeaderModal">
            <Modal.Title>PO History</Modal.Title>
          </Modal.Header>
          <Modal.Body className="deleteBodyModal">
            {POLogsData &&
              POLogsData.map((logData, index) => {
                const logObject = {
                  id: logData.id,
                  fileName: logData.fileName,
                  status: logData.status,
                  remarks: logData.remarks,
                  createdAt: logData.createdAt,
                  updatedAt: logData.updatedAt,
                };
                // console.log(`Log Data ${index + 1}:`, logObject);
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "1%",
                      borderBottom: "3px solid silver",
                    }}
                  >
                    <h5>Log {index + 1}</h5>
                    <p id="deleteApiData" style={{margin: 0}}>ID: {logObject.id}</p>
                    <p  id="deleteApiData"style={{margin: 0}}>File Name: {logObject.fileName}</p>
                    <p  id="deleteApiData"style={{margin: 0}}>Status: {<span id={logObject.status === "Success" ? "deleteApiData_status" :"deleteApiData_status1"}>
                    {logObject.status}</span>}</p>
                    <p id="deleteApiData" style={{margin: 0}}>Remarks: {logObject.remarks}</p>
                    <p  id="deleteApiData"style={{margin: 0}}>Created At: {logObject.createdAt}</p>
                    {/* <p id="deleteApiData" style={{margin: 0}}>Updated At: {logObject.updatedAt}</p> */}
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete btn Modal */}
        <Modal
        className="deleteModal"
        show={viewDeleteModal}
        onHide={() => setViewDeleteModal(false)}
        animation={false}
        scrollable={true}
    >
        <Modal.Header closeButton className="dleteHeaderModal">
            <Modal.Title>PO Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="deleteBodyModal">
        {deletePO && deletePO.data.length > 0 ?([...deletePO.data].reverse().map((item, index) => (
        <div key={index} className="deleteBodyModalContent">
            <div className="deleteHeadingLog">Log {index+1}</div>
            <div id="deleteApiData">ID: {item.id}</div>
            <div id="deleteApiData">File Name: {item.fileName}</div>
            <div id="deleteApiData">Status: <span id={item.status === "Success" ? "deleteApiData_status" :"deleteApiData_status1"}>
                  {item.status}</span>
                  </div>
            <div id="deleteApiData">Remark: {item.remark}</div>
            <div id="deleteApiData">Created At: {item.createdAt}</div>
            {/* <div id="deleteApiData">Updated At: {item.updatedAt}</div> */}
            <div style={{border:".1px solid silver",margin:"1%"}}></div>
        </div>
    ))):(<div id="deleteApiData">No Delete data on display...</div>)
}
        </Modal.Body>
        <Modal.Footer className="deleteFooterModal">
            <button className="btn btn-secondary" id="deleteBtn" onClick={() => setViewDeleteModal(false)}>
                Close
            </button>
        </Modal.Footer>
    </Modal>
      </div>
    </div>
  );
}

export default PoFileLoadingStatus;
