import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import { Col, Form, Modal, Row, Spinner, Button } from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../../context/context";
import Loader from "../../components/Loader";
import { debounce } from "underscore";
import toast from "react-hot-toast";
import convertTimeToCountry from "../../utility/convertTimeToCountry";
import { useLayoutEffect } from "react";
import ErrosToast from "../../components/ErrosToast";
// import { useStateManager } from "react-select";

const ExFactory = () => {
  const { selectedItems, setSelectedItems } = useContext(Context);
  const { processItems, setProcessItems } = useContext(Context); // to store data in sent or send data to shipment Loading
  const [sentItems, setSentItems] = useState([]); // to strore data for sent

  // const [selectedItems, setSelectedItems] = useState([])
  const [selectAll, setSelectAll] = useState(false); //checking selecting all or not
  const [data, setData] = useState([]); // total data including sent and unsent
  const [unsentData, setUnsentData] = useState([]); // sent data in this
  const [isLoading, setIsLoading] = useState(false);
  const [sentCheckBox, setSentCheckBox] = useState(false);
  const [unsentCheckBox, setUnsentCheckBox] = useState(true);

  const [poSearch, SetPoSearch] = useState("");
  const [stySearch, SetStySearch] = useState("");
  const [fromSearch, SetFromSearch] = useState("");
  const [toSearch, SetToSearch] = useState("");

  const [BoxGroupSearch, SetBoxGroupSearch] = useState("");

  const [all_tot_qty, setAll_tot_qty] = useState(null); // for
  // console.log("all_tot_qty", all_tot_qty);
  const [all_tot_carton, setAll_tot_carton] = useState(null);
  // const [selectedData]
  const [selectedTot_qty, setSelectedTot_qty] = useState(0); //selecteded total quantity for print in confirmation box
  const [isProcessLoading, setIsProcessLoading] = useState(false); //loading for process button
  const [isRestoreLoading, setIsRestoreLoading] = useState(false); //loading for restore button
  const [showModal, setShowModal] = useState(false);
  const [inputForBarcode, setInputForBarcode] = useState("");
  const [boxnumberofBarcode, setboxnumberofBarcode] = useState("");
  const [totalQuantityofBarcode, settotalQuantityofBarcode] = useState("");

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // console.log("newww", PO);
  // console.log("process items", processItems);
  // console.log(
  //   "selected items",
  //   selectedItems.map((item) => item.id)
  // );
  // console.log('contxt', ctx)
  // console.log('check sentcheckBox', sentCheckBox)
  // console.log('check unsentCheckBox', unsentCheckBox)
  // console.log('sent data', sentItems)

  const fetchData = async (pageNo, limit) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/barcode/getExFactoryData?sent=${sentCheckBox}&unsent=${unsentCheckBox}&PO=${poSearch}&STY=${stySearch}&From=${fromSearch}&To=${toSearch}&BoxGroup=${BoxGroupSearch}`
      );
      // console.log("res", res);
      if (res.data && res.data.barcodes.length > 0) {
        const tot_qty = res.data.barcodes.reduce(
          (acc, b) => acc + parseInt(b.TOT_QTY, 10),
          0
        );
        // console.log("line78", tot_qty);
        const tot_carton_no = res.data.barcodes.length;
        console.log("line80", tot_carton_no);

        setAll_tot_qty(tot_qty);
        setAll_tot_carton(tot_carton_no);
        setData(res.data.barcodes);
        setSentItems(sentCheckBox ? res.data.barcodes : []);
        setUnsentData(unsentCheckBox ? res.data.barcodes : []);
      } else {
        // Reset data if no barcodes are returned
        setData([]);
        setAll_tot_qty(0);
        setAll_tot_carton(0);
      }

      setSelectAll(false);
      setSelectedItems([]);
      setSelectedTot_qty(0);
    } catch (err) {
      // Handle the error if needed
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   // fetchData(0,30)
  //   // if (!initialFetchDone) {
  //   fetchData();
  //   // Set initialFetchDone to true after initial fetch
  //   // return;
  //   // }

  //   // const tableContainer = document.querySelector(".table-container");
  //   // if (!tableContainer) return;

  //   // const handleScroll = () => {
  //   //   const table = tableContainer.querySelector(".table");

  //   //   if (!table) return;

  //   //   const tableBody = table.querySelector("tbody");

  //   //   // Add this check
  //   //   if (!tableBody) return;

  //   //   const lastRow = tableBody.querySelector("tr:last-child");

  //   //   if (lastRow) {
  //   //     console.log("boxapi length", data.length);
  //   //     const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
  //   //     const containerOffset =
  //   //       tableContainer.scrollTop + tableContainer.clientHeight;
  //   //     const bottomOffset = 20;

  //   //     if (
  //   //       containerOffset > lastRowOffset - bottomOffset &&
  //   //       hasMore &&
  //   //       !isLoading
  //   //     ) {
  //   //       fetchData(0, 30 * ((data.length + 30) / 30));
  //   //     }
  //   //   }
  //   // };

  //   // tableContainer.addEventListener("scroll", handleScroll);

  //   // return () => {
  //   //   tableContainer.removeEventListener("scroll", handleScroll);
  //   // };
  // }, []);
  // console.log("sent and unsent", sentCheckBox, unsentCheckBox);


  // useLayoutEffect(() => {
  //   if (
  //     poSearch.length < 5 ||
  //     stySearch.length < 6 ||
  //     BoxGroupSearch.length < 12
  //   ) {
  //     axios
  //       .get(
  //         `/barcode/getExFactoryData?sent=${sentCheckBox}&unsent=${unsentCheckBox}`
  //       )
  //       .then((res) => {
  //         // console.log("line163", res.data.barcodes);
  //         const tot_qty = res.data.barcodes.reduce((acc, b) => {
  //           const qty = parseInt(b.TOT_QTY, 10);
  //           return acc + (isNaN(qty) ? 0 : qty); // Add only valid numbers
  //         }, 0);
  //         // console.log("line168", tot_qty);
  //         const tot_carton_no = res.data.barcodes.length;
  //         console.log("line171", tot_carton_no);
  //         setAll_tot_qty(tot_qty);
  //         setAll_tot_carton(tot_carton_no);
  //       });
  //   }

  //   setSelectAll(false);
  //   setSelectedItems([]);
  //   setSelectedTot_qty(0);
  // }, [sentCheckBox, unsentCheckBox]);
  // console.log('select all not not', selectAll)

  // useEffect(() => {
  //   // for loadng onlysent data to set total quantity
  //   // setIsLoading(true);
  //   axios
  //     .get(`/barcode/getExFactoryData?sent=true&unsent=false`)
  //     .then((res) => {
  //       // console.log("send data", res.data);
  //       setSentItems(res.data.barcodes);
  //     });
  // }, []);
  // console.log("sent data", sentItems);

  const handleCheckboxChange = (event, item) => {
    // console.log('data', data)
    // console.log('selected item', item)
    // const tot_data = data.filter(dataItem => item===dataItem.id);
    // console.log('total data', tot_data)

    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedItems([...selectedItems, item]);
      setSelectedTot_qty((prev) => prev + parseInt(item.TOT_QTY, 10));
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
      setSelectedTot_qty((prev) => prev - parseInt(item.TOT_QTY, 10));
    }
  };

  // console.log('selected items', selectedItems)

  const handleSelectAllChange = (e) => {
    e.stopPropagation();
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      // console.log('sentItems', sentItems)
      const sentableItems = data.filter((item) => item.csv !== "1");
      // console.log("Sentable items:", sentableItems);
      // console.log("sent checkbox", sentCheckBox);
      const itemsToSelect = sentCheckBox ? sentableItems : data;
      // console.log("Items to select:", itemsToSelect);

      setSelectedItems(itemsToSelect);
      // console.log("Selected items:", selectedItems);

      const totalQty = itemsToSelect.reduce(
        (acc, b) => acc + parseInt(b.TOT_QTY, 10),
        0
      );
      // console.log("Total quantity:", totalQty);

      setSelectedTot_qty(totalQty);
    } else {
      setSelectedItems([]);
      setSelectedTot_qty(0);
    }
  };
  // console.log('from outside selected items', selectedItems)
  // console.log('select all from outside', selectAll)

  const handleSendToShipmentTab = async () => {
    // console.log('data',data)
    // const tot_data = data.
    try {
      if (!selectedItems || selectedItems.length === 0) {
        alert("Please select something to process");
        return;
      }
      setIsProcessLoading(true);
      if (
        window.confirm(
          `Are you sure to send below data from exfactory to shiploading\n Total Boxes :  ${selectedItems.length} Boxes \n Total Quantity : ${selectedTot_qty}`
        )
      ) {
        await sentToLoadingAPI();
        setIsProcessLoading(false);
        // navigate("/shipmentLoading");
      } else {
        setIsProcessLoading(false);
        setSelectedItems([]);
        setSelectAll(false);
        setSelectedTot_qty(0);
      }
    } catch (error) {
      setIsProcessLoading(false);
      console.error("Error processing APIs:", error);
    }
  };

  // console.log('is process loading', isProcessLoading)

  const restoreToLoadingAPI = () => {
    return new Promise((resolve, reject) => {
      const postData = {
        barcodes: selectedItems.map((item) => item.id),
      };
      axios
        .post("/barcode/unsendLoading", postData)
        .then((res) => {
          // console.log("unload data", res.data);
          // console.log("first api called");
          //setProcessItems(res.data);
          toast.remove();
          toast.success("Restored done");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      // resolve();
    });
  };
  // console.log("is store loading", isRestoreLoading);
  const handleRestoreToBarcodePdf = async () => {
    try {
      if (!selectedItems || selectedItems.length === 0) {
        alert("Please select something to restore");
        return;
      }
      setIsRestoreLoading(true);
      const confirmRestore = window.confirm(
        `Are you sure to restore below data from exfactory to barcodePdf\n Total Boxes :  ${selectedItems.length} Boxes \n Total Qunatity : ${selectedTot_qty}`
      );
      if (confirmRestore) {
        await restoreToLoadingAPI();
        // setUnsentCheckBox(true);
        // setSentCheckBox(false);
        setIsRestoreLoading(false);
        setIsLoading(true);
        const res = await axios.get(
          `/barcode/getExFactoryData?sent=${sentCheckBox}&unsent=${unsentCheckBox}&PO=${poSearch}&STY=${stySearch}&BoxGroup=${BoxGroupSearch}`
        );
        // console.log("second api called");
        // console.log("unsent data", res.data);
        const tot_qty = res.data.barcodes.reduce(
          (acc, b) => acc + parseInt(b.TOT_QTY, 10),
          0
        );
        const tot_carton_no = res.data.barcodes.length;
        // console.log('tot', tot_carton_no)
        setAll_tot_qty(tot_qty);
        setAll_tot_carton(tot_carton_no);
        if (sentCheckBox === true && unsentCheckBox === false) {
          setSentItems(res.data.barcodes);
        }
        // if (res.data.barcodes.length < 30) {
        //   setHasMore(false);
        // } else {
        //   setHasMore(true);
        // }
        setUnsentData(res.data.barcodes);
        setData(res.data.barcodes);
        setSelectAll(false);
        setSelectedItems([]);
        setSelectedTot_qty(0);
        setIsLoading(false);
      } else {
        setIsRestoreLoading(false);
        setSelectedItems([]);
        setSelectAll(false);
        setSelectedTot_qty(0);
      }
    } catch (error) {
      setIsRestoreLoading(false);
      console.error("Error processing APIs:", error);
    }
  };
  const sentToLoadingAPI = () => {
    return new Promise((resolve, reject) => {
      const postData = {
        barcodes: selectedItems.map((item) => item.id),
      };
      axios
        .post("/barcode/sendToLoading", postData)
        .then((res) => {
          // console.log("res data", res.data);
          setProcessItems(res.data);
          navigate("/shipmentLoading");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      resolve();
    });
  };
  // console.log("outside sent value box", sentCheckBox);
  // console.log("outside unsent value box", unsentCheckBox);

  const filterHandle = async (
    e,
    poSearch,
    stySearch,
    fromSearch,
    toSearch,
    BoxGroupSearch,
    sentCheckBox,
    unsentCheckBox
  ) => {
    e.preventDefault();

    // console.log("whats the name", e.target.name);

    let sentValue, unsentValue;

    if (e.target.name === "sentCheckBox") {
      sentValue = e.target.checked;
      unsentValue = false;
    } else if (e.target.name === "unsentCheckBox") {
      unsentValue = e.target.checked;
      sentValue = false;
    }
    // else if(e.target.name === "From" && poSearch===""){
    //   toast.remove();
    //   ErrosToast('Please Enter PO  field First')

    // }
    // else if(e.target.name === "To" && poSearch===""){
    //   toast.remove();
    //   ErrosToast('Please Enter PO  field First')
    // }
    else {
      sentValue = sentCheckBox;
      unsentValue = unsentCheckBox;
    }
    // console.log("inside sent checkbox", sentCheckBox);
    // console.log("inside unsent checkbox", unsentCheckBox);
    // console.log('sent value box', sentValue);
    // console.log('unsent value box', unsentValue);

    setIsLoading(true);
    setSelectAll(false);
    const encodedSentValue = encodeURIComponent(sentValue);
    const encodedUnsentValue = encodeURIComponent(unsentValue);
    const encodedPoSearch = encodeURIComponent(poSearch);
    const encodedStySearch = encodeURIComponent(stySearch);
    const encodedFromSearch = encodeURIComponent(fromSearch);
    const encodedToSearch = encodeURIComponent(toSearch);
    const encodedBoxGroupSearch = encodeURIComponent(BoxGroupSearch);
    try {
      const res = await axios.get(
        `/barcode/getExFactoryData?&sent=${encodedSentValue}&unsent=${encodedUnsentValue}&PO=${encodedPoSearch}&STY=${encodedStySearch}&From=${encodedFromSearch}&To=${encodedToSearch}&BoxGroup=${encodedBoxGroupSearch}`
      );

      setIsLoading(false);
      if (sentValue === true && unsentValue === false) {
        setSentItems(res.data.barcodes);
      }
      setData(res.data.barcodes);
      if (res.data.barcodes.length < 30) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      const tot_qty = res.data.barcodes.reduce(
        (acc, b) => acc + parseInt(b.TOT_QTY, 10),
        0
      );
      const tot_carton_no = res.data.barcodes.length;

      setAll_tot_qty(tot_qty);
      setAll_tot_carton(tot_carton_no);
      setSelectAll(false);
      setSelectedItems([]);
      setSelectedTot_qty(0);
      // console.log("ress", res);
    } catch (err) {
      setIsLoading(false);
      // console.log(err);
    }
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (
        e,
        poSearch,
        stySearch,
        fromSearch,
        toSearch,
        BoxGroupSearch,
        sentCheckBox,
        unsentCheckBox
      ) =>
        filterHandle(
          e,
          poSearch,
          stySearch,
          fromSearch,
          toSearch,
          BoxGroupSearch,
          sentCheckBox,
          unsentCheckBox
        ),
      1000,
      false
    ),
    []
  );
  const handleShowModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  // console.log("sent items", sentItems);

  const verifyBarcode = (e) => {
    e.preventDefault();
    const el = data.find((row) => row.barcodeParity === inputForBarcode);
    if (el !== undefined) {
      // Barcode matched
      if (!selectedItems.includes(el)) {
        // If the el is not already selected, add it to selectedItems
        setSelectedItems([...selectedItems, el]);
        setSelectedTot_qty((prev) => prev + parseInt(el.TOT_QTY, 10));
        // console.log("selectedItems", selectedItems, el);
        settotalQuantityofBarcode(el.TOT_QTY);
        setboxnumberofBarcode(el.SNo);
        setInputForBarcode("");
      }
    } else {
      toast.remove();
      ErrosToast("Barcode didn't match");
      return;
    }
  };

  const handleHeaderClick = (event) => {
    // Only toggle checkbox if the click is outside the actual checkbox input
    if (event.target.type !== "checkbox") {
      handleSelectAllChange(!selectAll);
    }
  };

  return (
    <div>
      <div className="fixed-top">
        <div>
          <Header />
        </div>
        <div
          style={{
            background: "#ffff",
            // border: "2px solid red",
            paddingLeft: "3%",
          }}
        >
          <div className="d-flex flex-wrap p-2">
            {/* whiteSpace: 'nowrap' */}
            {/* <Col style={{ background: "#f5f8fa" }} className="fs-3 "> */}
            {/* <form
            onSubmit={(e) => {
              filterHandle(e, poSearch, stySearch, BoxGroupSearch);
            }}
            // className="d-flex align-items-center"
          > */}
            <form
              className="d-flex flex-wrap"
              onSubmit={(e) => {
                filterHandle(
                  e,
                  poSearch,
                  stySearch,
                  fromSearch,
                  toSearch,
                  BoxGroupSearch
                );
              }}
            >
              <div className="me-2" style={{ textTransform: "capitalize" }}>
                <Form.Label className="fs-6">Style</Form.Label>
                <Form.Control
                  className="mt-1"
                  type="text"
                  name="sty"
                  maxLength={8}
                  style={{ width: "140px" }}
                  value={stySearch}
                  title="you need to write atleast 5 character to display"
                  onChange={(e) => {
                    SetStySearch(
                      e.target.value.toUpperCase().replace(/%/g, "")
                    );
                    if (
                      e.target.value.length > 6 ||
                      poSearch.length > 6 ||
                      BoxGroupSearch.length > 12
                    ) {
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        e.target.value.toUpperCase(),
                        fromSearch,
                        toSearch,
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    } else {
                      setData([]);
                    }
                  }}
                  placeholder="Style"
                  autoComplete="off"
                />
              </div>
              <div className="me-2" style={{ textTransform: "capitalize" }}>
                <Form.Label className="fs-6">PO#</Form.Label>
                <Form.Control
                  className="mt-1"
                  type="text"
                  name="po"
                  maxLength={6}
                  style={{ width: "140px" }}
                  value={poSearch}
                  title="you need to write atleast 5 character to display"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/\D/g, "")
                      .toUpperCase();
                    SetPoSearch(value);

                    // Call the filter function when conditions match
                    if (
                      value.length > 5 ||
                      stySearch.length > 5 ||
                      BoxGroupSearch.length > 12
                    ) {
                      OnChangeHandleFilter(
                        e,
                        value,
                        stySearch,
                        fromSearch,
                        toSearch,
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    } else if (value === "") {
                      // Reset table when input is cleared
                      setData([]);
                      setAll_tot_carton(0);
                      setAll_tot_qty(0);
                    }
                  }}
                  placeholder="PO"
                  autoComplete="off"
                />
              </div>
              <div className="me-2" style={{ textTransform: "capitalize" }}>
                <Form.Label className="fs-6">box# from</Form.Label>
                <Form.Control
                  className="mt-1"
                  type="text"
                  name="From"
                  maxLength={10}
                  style={{ width: "140px" }}
                  value={fromSearch}
                  onChange={(e) => {
                    SetFromSearch(
                      e.target.value.toUpperCase().replace(/%/g, "")
                    );
                    // console.log("fromsearch", fromSearch);
                    if (poSearch === "") {
                      toast.remove();
                      ErrosToast("Please Enter PO  field First");
                      return;
                    }
                    if (
                      poSearch.length > 4 ||
                      stySearch.length > 4 ||
                      BoxGroupSearch.length > 12
                    ) {
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        e.target.value.toUpperCase(),
                        toSearch,
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    } else {
                      setData([]);
                    }
                  }}
                  placeholder="From"
                  autoComplete="off"
                />
              </div>

              <div className="me-2" style={{ textTransform: "capitalize" }}>
                <Form.Label className="fs-6">box# to</Form.Label>
                <Form.Control
                  className="mt-1"
                  type="text"
                  name="To"
                  maxLength={10}
                  style={{ width: "140px" }}
                  value={toSearch}
                  onChange={(e) => {
                    SetToSearch(e.target.value.toUpperCase().replace(/%/g, ""));
                    if (poSearch === "") {
                      toast.remove();
                      ErrosToast("Please Enter PO  field First");
                      return;
                    }
                    if (
                      poSearch.length > 4 ||
                      stySearch.length > 4 ||
                      BoxGroupSearch.length > 12
                    ) {
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        fromSearch,
                        e.target.value.toUpperCase(),
                        BoxGroupSearch,
                        sentCheckBox,
                        unsentCheckBox
                      );
                    } else {
                      setData([]);
                    }
                  }}
                  placeholder="To"
                  autoComplete="off"
                />
              </div>
              <div className="me-2" style={{ textTransform: "capitalize" }}>
                <Form.Label className="fs-6">Box Group</Form.Label>
                <Form.Control
                  className="mt-1"
                  type="text"
                  name="BoxGroup"
                  maxLength={15}
                  style={{ width: "140px" }}
                  value={BoxGroupSearch}
                  onChange={(e) => {
                    SetBoxGroupSearch(e.target.value.toUpperCase());
                    if (
                      stySearch.length > 4 ||
                      poSearch.length > 4 ||
                      e.target.value.length > 13
                    ) {
                      OnChangeHandleFilter(
                        e,
                        poSearch,
                        stySearch,
                        fromSearch,
                        toSearch,
                        e.target.value.toUpperCase(),
                        sentCheckBox,
                        unsentCheckBox
                      );
                    } else {
                      setData([]);
                    }
                  }}
                  placeholder="BoxGroup"
                  autoComplete="off"
                />
              </div>

              <div className="me-2" style={{ marginTop: "36px" }}>
                <Button
                  className="btn btn-primary text-center ms-3"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    // OnChangeHandleFilter(e, poSearch, stySearch, BoxGroupSearch);
                  }}
                >
                  Filter
                </Button>
              </div>
              {/* </form> */}
              {/* </Col> */}

              <div>
                <div className="" style={{ marginTop: "36px" }}>
                  {unsentCheckBox && (
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={handleSendToShipmentTab}
                      // disabled={sentCheckBox}
                    >
                      {isProcessLoading ? <Spinner size="sm" /> : "Process"}
                    </button>
                  )}
                </div>
                <div className="">
                  {sentCheckBox && (
                    <button
                      className="btn btn-danger "
                      type="button"
                      onClick={handleRestoreToBarcodePdf}
                      // disabled={unsentCheckBox}
                    >
                      {isRestoreLoading ? <Spinner size="sm" /> : "Restore"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="d-flex flex-wrap p-2">
            <div className="me-4" style={{ marginTop: "10px" }}>
              <form>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="unsentCheckBox"
                    id="exampleRadios1"
                    value={unsentCheckBox}
                    onChange={(e) => {
                      setUnsentCheckBox(true);
                      setSentCheckBox(false);
                      //setSelectAll(false);
                      //setSelectedItems([]);
                      // setInitialFetchDone(false);
                      setSelectedTot_qty(0);
                      if (
                        stySearch.length > 4 ||
                        poSearch.length > 4 ||
                        BoxGroupSearch.length > 13
                      ) {
                        OnChangeHandleFilter(
                          e,
                          poSearch,
                          stySearch,
                          fromSearch,
                          toSearch,

                          BoxGroupSearch,
                          sentCheckBox,
                          // unsentCheckBox
                          e.target.checked
                        );
                      } else {
                        setData([]);
                      }
                    }}
                    checked={unsentCheckBox}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    UnSent Loading
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sentCheckBox"
                    id="exampleRadios2"
                    value={sentCheckBox}
                    checked={sentCheckBox}
                    onChange={(e) => {
                      setSentCheckBox(true);
                      setUnsentCheckBox(false);
                      //setSelectAll(false);
                      //setSelectedItems([]);
                      setSelectedTot_qty(0);
                      // setInitialFetchDone(false);
                      if (
                        stySearch.length > 4 ||
                        poSearch.length > 4 ||
                        BoxGroupSearch.length > 13
                      ) {
                        OnChangeHandleFilter(
                          e,
                          poSearch,
                          stySearch,
                          fromSearch,
                          toSearch,

                          BoxGroupSearch,
                          // sentCheckBox,
                          e.target.checked,
                          unsentCheckBox
                        );
                      } else {
                        setData([]);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Sent Loading
                  </label>
                </div>
              </form>
            </div>
            <div style={{ marginTop: "10px" }} className="me-5">
              <div className="d-flex">
                <div>
                  <div>Boxes : {all_tot_carton ? all_tot_carton : 0}</div>
                  <div>Total_qty: {all_tot_qty ? all_tot_qty : 0}</div>
                </div>
                <div className="ms-3">
                  <div>Selected Boxes: {all_tot_carton>0?selectedItems.length:0}</div>
                  <div>Selected Total_qty: {selectedTot_qty}</div>
                </div>
              </div>
            </div>
            <div className="">
              <button
                className="btn btn-primary "
                style={{ marginTop: "13px" }}
                onClick={(e) => handleShowModal(e)}
              >
                Scan
              </button>
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                // size="xs"
                // dialogClassName="modal-right"
                style={{
                  position: "absolute",
                  top: "100px",
                  left: "850px",
                  // float: "left",
                  // transform: "translateX(-50%)",
                  // maxWidth: "1650px",
                  height: "300px",
                  // border: "2px solid red",
                  width: "800px",
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="fs-5">Scanner Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={verifyBarcode}>
                    <div className="d-flex">
                      <div>
                        <label htmlFor="message-text">NVE#</label>
                        <input
                          type="text"
                          className="ms-2 "
                          style={{
                            width: "180px",
                            height: "37px",
                            borderRadius: "4px",
                          }}
                          id="recipient-name"
                          name=" NVE#"
                          placeholder="NVE#"
                          autoComplete="off"
                          value={inputForBarcode}
                          onChange={(e) => {
                            const value = e.target.value;
                            setInputForBarcode(value.replace(/[^\d.]/g, ""));
                          }}
                        />
                      </div>
                      <div className="ms-2">
                        <button
                          className="btn btn-primary"
                          onClick={verifyBarcode}
                          // hidden
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </form>

                  <span>
                    <span className="mt-2">
                      <span className="me-2">
                        {" "}
                        {`Box Number : ${boxnumberofBarcode}`}
                      </span>
                      <span className="ms-4">{`Total quantity : ${totalQuantityofBarcode} `}</span>
                    </span>

                    {/* <button
                    style={{ float: "right", width: "70px", height: "40px" }}
                    type="button"
                    className="btn btn-secondary mt-4"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button> */}
                  </span>
                </Modal.Body>
                {/* <Modal.Footer>
            {/* <button type="button" className="btn btn-primary">
              Confirm
            </button> */}
                {/* </Modal.Footer> */}
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mx-4 tableFixHead table-container"
        style={{
          marginTop: "265px",
          marginBottom: "40px",
          // border: "2px solid red",
          height: "54vh",
        }}
      >
        {/* {isLoading && !initialFetchDone ? ( */}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive mx-4 tableFixHead table-container">
            <table className="table table-bordered table-hover table-sm table-header-for-all">
              <thead>
                <tr className="table-warning">
                  {/* <th onClick={handleHeaderClick} style={{ cursor: 'pointer' }}> */}
                  <th>
                    <span
                    // onClick={() => {
                    //   alert("It will Select All the PO");
                    // }}
                    // onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="checkbox"
                        // className="form-check-input"
                        style={{ cursor: "pointer" }}
                        name="All"
                        value="All"
                        checked={
                          selectAll &&
                          (selectedItems.length === data.length ||
                            selectedItems.length === sentItems.length)
                        }
                        onChange={(e) => handleSelectAllChange(e)}
                      />
                    </span>
                    Select
                  </th>
                  {/* <th>S.No.</th> */}
                  <th>PO#</th>
                  <th>Style</th>
                  <th>Box#</th>

                  <th>NVE Barcode</th>
                  <th>Box Group</th>
                  {/* <th>SequencID</th> */}

                  <th>TOT_QTY</th>

                  <th>Created At</th>

                  <th>User</th>
                  {sentCheckBox && <th>Loading Time</th>}
                </tr>
              </thead>
              {data.length > 0 ? (
                <tbody>
                  {data.map((el, i) => {
                    // console.log("mapped", el);
                    return (
                      <>
                        <tr key={i}>
                          <td>
                            {i + 1}
                            <input
                              type="checkbox"
                              name="All"
                              value="All"
                              // checked={selectAll || selectedItems.includes(el)}
                              checked={selectedItems.includes(el)}
                              onChange={(e) => handleCheckboxChange(e, el)}
                              disabled={el.csv == 1}
                            />
                          </td>
                          {/* <td>{i + 1}</td> */}
                          <td>{el.PO}</td>
                          <td>{el.STY}</td>
                          <td>{el.SNo}</td>

                          <td>{el.barcodeParity}</td>
                          <td>{el.BoxGroup}</td>

                          <th>{el.TOT_QTY}</th>
                          <td>{convertTimeToCountry(el.createdAt)}</td>
                          <td>{el.username}</td>
                          {sentCheckBox && (
                            <td>{convertTimeToCountry(el.loadingTime)}</td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="10"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {poSearch.length > 5 ||
                      stySearch.length > 6 ||
                      BoxGroupSearch.length > 12 ? (
                        <h5>NO DATA FOUND</h5>
                      ) : (
                        <h5>
                          Please write any PO or Style or BoxGroup to display
                          any data
                        </h5>
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            {/* {!isLoading && !hasMore && <div>No more data</div>} */}
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExFactory;