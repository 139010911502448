import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import context from "../context/context";
// import { Spinner } from "react-bootstrap";
import Loader from "./Loader";

const ProtectedRoute = (props) => {
  const ctx = useContext(context);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = useCallback(() => {
    const userToken = localStorage.getItem("token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/");
    }

    // Set the protected Route Logic, if ACLkey will be true , it will reach the url else it will throw an alert
    // console.log(
    //   props.aclKey,
    //   ctx.userData[props.aclKey],
    //   typeof ctx.userData[props.aclKey],
    //   ctx.userData
    // );
    if (props.aclKey && ctx.userData[props.aclKey]) setIsLoggedIn(true);
    else {
      alert(
        "You are not authorized to access this. Please contact to administrator"
      );
      navigate("/poSummary");
    }
  });

  useEffect(() => {
    const userData = ctx.userData;
    ctx.setUserData(userData);
    userData && checkUserToken();
  }, [ctx]);
  return (
    <div className="app-content content overflow-hidden">
      {isLoggedIn ? (
        props.children
      ) : (
        <div
          // className="d-flex text-center justify-content-center mt-50px"
          style={{
            // border: "2px solid red",
            height: "99vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5%",
          }}
        >
          {/* ...Loading <Spinner size="lg"></Spinner> */}
          <Loader />
        </div>
      )}
    </div>
  );
};
export default ProtectedRoute;
