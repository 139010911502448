import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap"; // Import Col, Form.Group, and Form.Control from react-bootstrap
import "./InputBox.css";
import context from "../../context/context";
import shipContext from "../../context/shipInfoContext";

import TimePicker from "react-time-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedTextInput from "react-text-mask";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
import "./TimePicker.css";
import axios from "axios";

const InputBox = ({
  labelName,
  inputName,
  placeholder,
  value,
  disabled,
  suggestionList,
  setSuggestionList,
  activeSuggestion,
  setActiveSuggestion,
  isDisabled,
  sentShipmentSuggestion,
  disableAfterSelect,
  setDisableAfterSelect,
  firstRow,
  secondRow,
  onChangeDate,
  onChange,
}) => {
  // Initialize state with default value
  // const [formData1, setFormData1] = useState({
  //   consignee: "Lerros",
  // });
  // const [lerrosValue, setLerrosValue] = useState({ shipGroup: "" });
  const { formData, setFormData } = useContext(context);
  // console.log("formData",formData)
  const { checkUpdateButton, setCheckUpdateButton } = useContext(shipContext); // to check if existing data is fetched or not

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(true); // for showing suggestions
  const [suggestionClicked, setSuggestionClicked] = useState(false);

  const suggestionsRef = useRef(null);

  const showButton1 = inputName === "awbbl1";
  const showButton2 = inputName === "containerNo1";
  const showButton3 = inputName === "cartons1";

  const isDateInput =
    inputName === "etd" ||
    // inputName === "currentTime" ||
    inputName === "shipmentCrDate" ||
    inputName === "etaLager" ||
    inputName === "lastEtaLager" ||
    inputName === "awbbldate" ||
    inputName === "etaPort";

  const factoryShipmentInput = inputName === "shipNo";

  const isCurrentTimeInput = inputName === "currentTime";

  // const handleClick = ()=> {
  //   console.log('lol')
  // }

  const handleCloseModal1 = () => setShowModal1(false);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleCloseModal3 = () => setShowModal3(false);

  const handleShowModal1 = (e) => {
    e.preventDefault();
    setShowModal1(true);
    setShowModal2(false);
    setShowModal3(false);
  };
  const handleShowModal2 = (e) => {
    e.preventDefault();
    setShowModal1(false);
    setShowModal2(true);
    setShowModal3(false);
  };

  const handleShowModal3 = (e) => {
    e.preventDefault();
    setShowModal1(false);
    setShowModal2(false);
    setShowModal3(true);
  };

  // Handle input change
  // const onChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData1((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleInputBlur = () => {
  //   if (!suggestionClicked) {
  //     setShowSuggestions(false); // Hide suggestions only if a suggestion wasn't clicked
  //   }
  // };

  // const handleInputFocus = () => {
  //   setShowSuggestions(true); // Show suggestions when input gains focus
  // };

  function formatDate(timestamp) {
    // Create a new Date object from the timestamp
    // const date = new Date(timestamp);

    // Extract year, month, and day from the date object
    // const year = date.getFullYear();
    // const month = date.getMonth();
    // const day = date.getDate();
    const isoDate = new Date(timestamp);
    // console.log('isoDate', isoDate)
    if (isNaN(isoDate)) {
      // console.error(`Invalid date format: ${dateString.etd}`);
      return ""; // Return empty string for invalid date strings
    }

    // Create a new Date object with the extracted components and time set to midnight
    // const formattedDateWithTime = new Date(year, month, day);
    const formattedDateWithTime = isoDate.toISOString().split("T")[0];

    return formattedDateWithTime;
  }

  function convertUTCToLocalDate(date) {
    if (!date) {
      return null; // Ensure null is returned if no date is present
    }
    const localDate = new Date(date); // Convert to local timezone
    return new Date(
      localDate.getUTCFullYear(),
      localDate.getUTCMonth(),
      localDate.getUTCDate()
    );
  }
  
  function onChangeDate(date, fieldName) {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: date, // Update the selected date in the form data
    }));
  }

  const handleSuggestionClick = async (suggestion) => {
    // console.log('suggestion name', suggestion)
    // setSuggestionClicked(true);
    // setFormData({
    //   ...formData,
    //   shipNo: suggestion,
    // });
    // setSuggestionList([]);
    setCheckUpdateButton(true);
    // const check = sentShipmentSuggestion.includes(suggestion)
    // setCheckDisable(check);

    try {
      // Trigger API call to fetch data based on the clicked suggestion
      const response = await axios.get(`/shipInfo/check?shipNo=${suggestion}`);
      const responseData = response.data; // Assuming the API returns data in JSON format
      // console.log("response data after click suggestion", response.data);
      if (responseData.new === false && responseData.shipInfo) {
        const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        const onlyDateRegex = /^\d{4}-\d{2}-\d{2}$/;
        // const checkCurrentFormat =
        //   isoRegex.test(responseData.shipInfo.currentTime) ||
        //   onlyDateRegex.test(responseData.shipInfo.currentTime);
        const checkEtaFormat =
          isoRegex.test(responseData.shipInfo.etd) ||
          onlyDateRegex.test(responseData.shipInfo.etd);
        const checkEtaPortFormat =
          isoRegex.test(responseData.shipInfo.etaPort) ||
          onlyDateRegex.test(responseData.shipInfo.etaPort);
        const checkShipCrFormat =
          isoRegex.test(responseData.shipInfo.shipmentCrDate) ||
          onlyDateRegex.test(responseData.shipInfo.shipmentCrDate);
        const checkEtaLager =
          isoRegex.test(responseData.shipInfo.etaLager) ||
          onlyDateRegex.test(responseData.shipInfo.etaLager);
        const checkAwbbldate =
          isoRegex.test(responseData.shipInfo.awbbldate) ||
          onlyDateRegex.test(responseData.shipInfo.awbbldate);
        const checkActualEtd =
          isoRegex.test(responseData.shipInfo.actualEtd) ||
          onlyDateRegex.test(responseData.shipInfo.actualEtd);
        const checkActualEta =
          isoRegex.test(responseData.shipInfo.actualEta) ||
          onlyDateRegex.test(responseData.shipInfo.actualEta);
        // console.log('check eta format', checkEtaFormat)
        let formattedEtaPort,
          formattedEtd,
          // formattedCurrentTime,
          formattedShipmentCrDate,
          formattedEtaLager,
          formattedAwbbldate,
          formattedActualEtd,
          formattedActualEta;
        if (responseData.shipInfo.actualEta && checkActualEta) {
          formattedActualEta = formatDate(responseData.shipInfo.actualEta);
        }
        if (responseData.shipInfo.actualEtd && checkActualEtd) {
          formattedActualEtd = formatDate(responseData.shipInfo.actualEtd);
        }
        if (responseData.shipInfo.etaPort && checkEtaPortFormat) {
          formattedEtaPort = formatDate(responseData.shipInfo.etaPort);
        }
        if (responseData.shipInfo.etd && checkEtaFormat) {
          formattedEtd = formatDate(responseData.shipInfo.etd);
        }
        if (responseData.shipInfo.etaLager && checkEtaLager) {
          formattedEtaLager = formatDate(responseData.shipInfo.etaLager);
        }
        // if (responseData.shipInfo.currentTime && checkCurrentFormat) {
        //   formattedCurrentTime = formatDate(responseData.shipInfo.currentTime);
        // }
        if (responseData.shipInfo.shipmentCrDate && checkShipCrFormat) {
          formattedShipmentCrDate = formatDate(
            responseData.shipInfo.shipmentCrDate
          );
        }
        if (responseData.shipInfo.awbbldate && checkAwbbldate) {
          formattedAwbbldate = formatDate(responseData.shipInfo.awbbldate);
        }

        // console.log("lets see", formattedEtaPort);
        // console.log("lets see current time", formattedCurrentTime);
        // console.log("type ", typeof formattedEtaPort);

        // etaPort: formattedEtaPort || null,
        // shipmentCrDate: formattedShipmentCrDate || null,
        // etd: formattedEta || null,
        // currentTime: formattedCurrentTime|| null
        setFormData({
          ...responseData.shipInfo,
          etaPort: formattedEtaPort || null,
          shipmentCrDate: formattedShipmentCrDate || null,
          etd: formattedEtd || null,
          // currentTime: formattedCurrentTime || null,
          etaLager: formattedEtaLager || null,
          awbbldate: formattedAwbbldate || null,
          actualEtd: formattedActualEtd || null,
          actualEta: formattedActualEta || null,
        });
      } else {
        setFormData({
          ...formData,
          shipNo: suggestion,
        });
      }
      setSuggestionList([]);
      setSuggestionClicked(true);
      setDisableAfterSelect(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // console.log("suggestion list", suggestionList);
  const handleShowSuggestions = (e) => {
    // if (factoryShipmentInput) {
    // e.preventDefault();
    setTimeout(() => setSuggestionList([]), 300);
    // }
  };

  const handleKeyDown = (e) => {
    // if (factoryShipmentInput) {
    // e.preventDefault();
    if (e.key === "ArrowDown") {
      if (activeSuggestion + 1 < suggestionList.length) {
        setActiveSuggestion((prev) => prev + 1);
        scrollIntoViewIfNeeded(activeSuggestion + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestion - 1 >= 0) {
        setActiveSuggestion((prev) => prev - 1);
        scrollIntoViewIfNeeded(activeSuggestion - 1);
      }
    } else if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      // setShowSuggestions(false);
      handleSuggestionClick(suggestionList[activeSuggestion]);
      // onChange({ target: { value: suggestionList[activeSuggestion] } });
    }
    // }
  };

  const scrollIntoViewIfNeeded = (index) => {
    const element = suggestionsRef.current?.children[index];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  // console.log('date value', value)

  const preventEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  // const valueHandler = (e) => {
  //   // alert();
  //   const { name, value } = e.target;
  //   // console.log("value===>>>>", value);
  //   setLerrosValue((preValue) => ({
  //     ...preValue,
  //     [name]: value,
  //   }));
  //   console.log(lerrosValue);
  // };

  // const getValue = (e) => {
  //   e.preventDefault();
  //   console.log(lerrosValue);
  // };

  // date select and new new select function


  return (
    <>
      {firstRow && !secondRow && (
        <>
          <div
            style={{
              display: "flex",
              // border: "2px solid red",
              marginBottom: "1%",
            }}
          >
            <Col sx={6} md={6} lg={6}></Col>
            <Col sx={6} md={6} lg={6}>
              
              <Row className="" >
              <div
                style={{
                  display: "flex",
                  flexDirection:"column",
                  // border: "2px solid black",
                  width: "100%",
                  // marginBottom: "2%",
                }}
              >
                <div
                  style={{
                    // border: "2px solid green",
                    width: "30%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingBottom: "1%",
                    height:"fit-content",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "rgb(34, 34, 34)",
                  }}
                >
                Ship# :
                </div>
                <div
                  style={{
                    // border: "2px solid blue",
                    width: "100%",
                  }}
                >
                  <Form.Control
                    type="text"
                    name={"shipNo"}
                    placeholder={"Factory Shipment(6-20)"}
                    value={formData["shipNo"]}
                    onChange={onChange}
                    // onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    required
                    maxLength={20}
                    // maxLength={200}
                    // style={{ width: "100%" }}
                    disabled={disabled || (isDisabled && disableAfterSelect)}
                    onBlur={handleShowSuggestions}
                    onKeyDown={handleKeyDown}
                    onKeyPress={preventEnterKey}
                    autoComplete="off"
                  />
                  {showSuggestions && (
                    <div
                      // className="dropdown"
                      ref={suggestionsRef}
                      style={{
                        position: "absolute",
                        zIndex: 3,
                        // width: "100%",
                        maxHeight: "200px",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "scroll",
                        overflowX: "visible",
                        scrollSnapType: "mandatory",
                        visibility: "visible",
                        backgroundColor: "whitesmoke",
                        // border: '1pzx'
                        // borderWidth: showSuggestions ? '4px' : '0px',
                        // borderColor: 'rgb(183, 207, 248)' ,
                        borderStyle:
                          suggestionList.length > 0 ? "ridge" : "none",
                        listStyleType: "none",
                      }}
                    >
                      {suggestionList.map((suggestion, index) => {
                        let className;
                        if (index === activeSuggestion) {
                          className = "suggestion-active";
                        }
                        return (
                          <div
                            className=""
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{
                              padding: "2px",
                              cursor: "pointer",
                              backgroundColor:
                                index === activeSuggestion
                                  ? "#007BFF"
                                  : "transparent",
                              color:
                                index === activeSuggestion ? "#fff" : "#000",
                            }}
                          >
                            {/* {suggestion} */}
                            {sentShipmentSuggestion.includes(suggestion)
                              ? suggestion + " (Sent)"
                              : suggestion}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* <InputBox
                labelName={"Factory Shipment"}
                // inputName={"shipGroup"}
                maxLength={20}
                inputName={"shipNo"}
                placeholder={"Factory Shipment(6-20)"}
                value={formData["shipNo"]}
                // value={formData["shipGroup"]}
                onChange={handleInputChange}
                suggestionList={shipmentSuggestion}
                setSuggestionList={setShipmentSuggestion}
                sentShipmentSuggestion={sentShipmentSuggestion}
                activeSuggestion={activeSuggestion}
                setActiveSuggestion={setActiveSuggestion}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                </div>
              </div>
            </Row>
            </Col>
          </div>
          <Col sx={6} md={4} lg={4}>
            <Row>
              <pre>To: Lerros Modern GmbH</pre>
            </Row>
            <Row>
              <pre style={{ paddingLeft: "10%" }}> Department</pre>
            </Row>
          </Col>
          <Col sx={6} md={4} lg={4}>
          <Row className="">
              <div style={{
                // border:"2px solid red",
                display:"flex",
                justifyContent:"space-between",
                marginBottom:"2%",
                paddingLeft:"4%",
                width:"100%"
                }}>
                <div style={{
                  // border:"2px solid blue", 
                  display:"flex", 
                  justifyContent:"center", 
                  alignItems:"center"
                }}><h5>Lerros Shipment:</h5></div>
                <div  id="lerros_input" style={{
                  // border:"2px solid green",
                  width:"180px"
                  }}>
                    <input class="form-control" 
                    type="text" 
                    name="shipGroup"
                    placeholder="Lerros Shipment"
                    maxLength={8}
                    value={formData.shipGroup}
                    onChange={onChange}
                    // disabled={false}
                    isDisabled={false} 
                    setDisableAfterSelect={() => {}}
                    disableAfterSelect={false} 
                    ></input>
                {/* <InputBox
                  style={{width:"150px"}}
                  // labelName={"Lerros Shipment"}
                  inputName={"shipGroup"}
                  maxLength={8}
                  placeholder={"Lerros Shipment"}
                  value={formData.shipGroup}
                  onChange={onChange}
                  // disabled={false}
                  isDisabled={false} // Make sure this prop is used in InputBox if needed
                  setDisableAfterSelect={() => {}}
                  disableAfterSelect={false} // Ensure this prop is used in InputBox if needed
                /> */}
                </div>
              </div>
            </Row>
            <Row className="">
              <div
                style={{
                  display: "flex",
                  // border: "2px solid black",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    // border: "2px solid green",
                    // width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  <h5>Current Date:</h5>
                </div>
                <div
                  style={{
                    //  border: "2px solid blue",
                    width: "50%",
                  }}
                >
                  <div className="date-picker-wrapper">
                    <DatePicker
                      // labelName={"E.T.D."}
                      className="form-control"
                      wrapperClassName="datePicker"
                      // style={{ width: "300px" }}
                      name={"shipmentCrDate"}
                      // selected={value ? new Date(value) : null}
                      selected={convertUTCToLocalDate(formData["shipmentCrDate"])}
                      onChange={(date) => onChangeDate(date, "shipmentCrDate")}
                      dateformat="dd/MM/yyyy" // Customize date format if needed
                      placeholderText="Select a date"
                      showMonthDropdown
                      showYearDropdown
                      customInput={
                        <MaskedTextInput
                          type="text"
                          dateformat="dd/mm/yyyy"
                          mask={[
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          onKeyPress={preventEnterKey}
                        />
                      }
                      // showPopperArrow={true}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </Row>
          </Col>
          <Col sx={6} md={4} lg={4}>
            <Row className="">
              <div
                style={{
                  display: "flex",
                  // border: "2px solid black",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "2%",
                }}
              >
                <div
                  style={{
                    // border: "2px solid green",
                    width: "40%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <h5>From Country:</h5>
                </div>
                <div sx={6} md={3} lg={6}>
                  <Form.Control
                    type="text"
                    name={"countryOfOrigin"}
                    size="sm"
                    placeholder={"Country Of Origin"}
                    value={formData["countryOfOrigin"]}
                    onChange={onChange}
                    disabled={disabled || (isDisabled && disableAfterSelect)}
                    onKeyPress={preventEnterKey}
                    autoComplete="off"
                  />
                  {/* <InputBox
                labelName={"Country Of Origin"}
                inputName={"countryOfOrigin"}
                placeholder={"Country Of Origin"}
                value={formData["countryOfOrigin"]}
                onChange={handleInputChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                </div>
              </div>
            </Row>

            <Row>
              <div
                style={{
                  display: "flex",
                  // border: "2px solid black",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "2%",
                }}
              >
                <div
                  style={{
                    // border: "2px solid green",
                    width: "34%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <h5>Factory :</h5>
                </div>

                <div sx={6} md={3} lg={6}>
                  <Form.Control
                    type="text"
                    name={"factory"}
                    size="sm"
                    placeholder={"factory"}
                    value={formData["factory"]}
                    onChange={onChange}
                    disabled={disabled || (isDisabled && disableAfterSelect)}
                    onKeyPress={preventEnterKey}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Row>
          </Col>
        </>
      )}
      {!firstRow && secondRow && (
        <>
          <Col>
            <Card>
              <Card.Body>
                <Row className="mb-1">
                  <Col>Consignee</Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="consignee"
                      size="sm"
                      placeholder="Lerros"
                      value={formData.consignee}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>Mode of shippment</Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name={"shipVia"}
                      placeholder={"Ship Via"}
                      value={formData["shipVia"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>No. Of Cartons</Col>
                  <Col>
                    <Form.Control
                      type="text"
                      maxLength={5}
                      name={"cartons1"}
                      placeholder={"Cartons1"}
                      value={formData["cartons1"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                    <button
                      className="icon-btn"
                      // data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                      style={{
                        border: "none",
                        background: "none",
                        outline: "none",
                      }}
                      onClick={(e) => handleShowModal3(e)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="20"
                        height="20"
                      >
                        <path
                          fill="currentColor"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    </button>
                  </Col>
                  <Modal show={showModal3} onHide={handleCloseModal3} style={{display:"flex", justifyContent:"center", alignItems:"center",  paddingTop:"130px"}}>
                    <Modal.Header closeButton>
                      <Modal.Title className="fs-5">Cartons</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ width :"550px",}}>
                      <form>
                        <div className="mb-2">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            cartons2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="recipient-name"
                            name="cartons2"
                            maxLength={5}
                            // placeholder={placeholder}
                            value={formData["cartons2"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            cartons3
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength={5}
                            id="recipient-name"
                            name="cartons3"
                            // placeholder={placeholder}
                            value={formData["cartons3"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            cartons4
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength={5}
                            id="recipient-name"
                            name="cartons4"
                            // placeholder={placeholder}
                            value={formData["cartons4"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        <div className="">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            cartons5
                          </label>
                          <input
                            type="text"
                            maxLength={5}
                            className="form-control"
                            id="recipient-name"
                            name="cartons5"
                            // placeholder={placeholder}
                            value={formData["cartons5"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        {/* <div className="mb-2">
                <label htmlFor="recipient-name" className="col-form-label">
                  cartons6
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recipient-name"
                  name="cartons6"
                  // placeholder={placeholder}
                  value={formData["cartons6"]}
                  onChange={onChange}
                />
              </div> */}
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCloseModal3}
                      >
                        Confirm
                      </button>
                      {/* <button type="button" className="btn btn-primary">
              Confirm
            </button> */}
                    </Modal.Footer>
                  </Modal>
                </Row>
                <Row className="mb-1">
                  <Col>Carrier Planned</Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name={"mv"}
                      placeholder={"MV"}
                      value={formData["mv"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>Planned ETD/ETA</Col>
                  <Col>
                    <div className="date-picker-wrapper">
                      <DatePicker
                        // labelName={"E.T.D."}
                        className="form-control"
                        wrapperClassName="datePicker"
                        // style={{ width: "300px" }}
                        name={"etd"}
                        // selected={value ? new Date(value) : null}
                        selected={convertUTCToLocalDate(formData["etd"])}
                        onChange={(date) => onChangeDate(date, "etd")}
                        // onChange={(date) => onChangeDate(date, "etd")}
                        dateformat="dd/MM/yyyy" // Customize date format if needed
                        placeholderText="Select a date"
                        showMonthDropdown
                        showYearDropdown
                        customInput={
                          <MaskedTextInput
                            type="text"
                            dateformat="dd/mm/yyyy"
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            onKeyPress={preventEnterKey}
                          />
                        }
                        // showPopperArrow={true}
                        disabled={isDisabled && disableAfterSelect}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="date-picker-wrapper">
                      <DatePicker
                        // labelName={"E.T.D."}
                        className="form-control"
                        wrapperClassName="datePicker"
                        // style={{ width: "300px" }}
                        name={"etaPort"}
                        // selected={value ? new Date(value) : null}
                        selected={convertUTCToLocalDate(formData["etaPort"])}
                        onChange={(date) => onChangeDate(date, "etaPort")}
                        dateformat="dd/MM/yyyy" // Customize date format if needed
                        placeholderText="Select a date"
                        showMonthDropdown
                        showYearDropdown
                        customInput={
                          <MaskedTextInput
                            type="text"
                            dateformat="dd/mm/yyyy"
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            onKeyPress={preventEnterKey}
                          />
                        }
                        // showPopperArrow={true}
                        disabled={isDisabled && disableAfterSelect}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row className="mb-1">
                  <Col>Actual Carrier </Col>
                  <Col>
                    {/* actualCarrier */}
                    <Form.Control
                      type="text"
                      name={"actualCarrier"}
                      placeholder={"Actual Carrier"}
                      value={formData["actualCarrier"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col>AWB/BL1# </Col>
                  <Col>
                    <Form.Control
                      className="input-with-icon"
                      type="text"
                      name={"awbbl1"}
                      placeholder={"awb_b_l1"}
                      value={formData["awbbl1"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />

                    <button
                      className="icon-btn-awbbl"
                      // data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                      style={{
                        border: "none",
                        background: "none",
                        outline: "none",
                      }}
                      onClick={(e) => handleShowModal1(e)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="20"
                        height="20"
                      >
                        <path
                          fill="currentColor"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    </button>
                  </Col>
                  <Modal show={showModal1} onHide={handleCloseModal1}style={{display:"flex", justifyContent:"center", alignItems:"center",  paddingTop:"130px"}}>
                    <Modal.Header closeButton>
                      <Modal.Title className="">awbbl</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ width :"550px",}}>
                      <form>
                        <div className="mb-2">
                          <label
                            htmlFor="recipient-name"
                            className="col-form-label"
                          >
                            awbbl2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="recipient-name"
                            name="awbbl2"
                            // placeholder={placeholder}
                            value={formData["awbbl2"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            awbbl3
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="recipient-name"
                            name="awbbl3"
                            // placeholder={placeholder}
                            value={formData["awbbl3"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            awbbl4
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="recipient-name"
                            name="awbbl4"
                            // placeholder={placeholder}
                            value={formData["awbbl4"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            awbbl5
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="recipient-name"
                            name="awbbl5"
                            // placeholder={placeholder}
                            value={formData["awbbl5"]}
                            onChange={onChange}
                            disabled={isDisabled && disableAfterSelect}
                            autoComplete="off"
                          />
                        </div>
                        {/* <div className="">
                <label htmlFor="message-text" className="col-form-label">
                  awbbl6
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recipient-name"
                  name="awbbl6"
                  // placeholder={placeholder}
                  value={formData["awbbl6"]}
                  onChange={onChange}
                />
              </div> */}
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCloseModal1}
                      >
                        Confirm
                      </button>
                      {/* <button type="button" className="btn btn-primary">
              Confirm
            </button> */}
                    </Modal.Footer>
                  </Modal>
                </Row>
                <Row className="mb-1">
                  <Col>Destination </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name={"destination"}
                      placeholder={"destination"}
                      value={formData["destination"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>Flt/Voyage/Truck #</Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name={"fltVoyageTruck"}
                      placeholder={"fltVoyageTruck"}
                      value={formData["fltVoyageTruck"]}
                      onChange={onChange}
                      disabled={disabled || (isDisabled && disableAfterSelect)}
                      onKeyPress={preventEnterKey}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>Actual ETD/ETA </Col>
                  <Col>
                    <div className="date-picker-wrapper">
                      <DatePicker
                        // labelName={"E.T.D."}
                        className="form-control"
                        wrapperClassName="datePicker"
                        // style={{ width: "300px" }}
                        name={"actualEtd"}
                        // selected={value ? new Date(value) : null}
                        selected={convertUTCToLocalDate(formData["actualEtd"])}
                        onChange={(date) => onChangeDate(date, "actualEtd")}
                        dateformat="dd/MM/yyyy" // Customize date format if needed
                        placeholderText="Select a date"
                        showMonthDropdown
                        showYearDropdown
                        customInput={
                          <MaskedTextInput
                            type="text"
                            dateformat="dd/mm/yyyy"
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            onKeyPress={preventEnterKey}
                          />
                        }
                        // showPopperArrow={true}
                        disabled={isDisabled && disableAfterSelect}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="date-picker-wrapper">
                      <DatePicker
                        // labelName={"E.T.D."}
                        className="form-control"
                        wrapperClassName="datePicker"
                        // style={{ width: "300px" }}
                        name={"actualEta"}
                        // selected={value ? new Date(value) : null}
                        selected={convertUTCToLocalDate(formData["actualEta"])}
                        onChange={(date) => onChangeDate(date, "actualEta")}
                        dateformat="dd-MM-yyyy" // Customize date format if needed
                        placeholderText="Select a date"
                        showMonthDropdown
                        showYearDropdown
                        customInput={
                          <MaskedTextInput
                            type="text"
                            dateformat="dd/mm/yyyy"
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            onKeyPress={preventEnterKey}
                          />
                        }
                        // showPopperArrow={true}
                        disabled={isDisabled && disableAfterSelect}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>Cargo Hand Over Date </Col>
                  <Col style={{ marginTop: "1%" }}>
                    <div className="date-picker-wrapper">
                      <DatePicker
                        // labelName={"E.T.D."}
                        className="form-control"
                        wrapperClassName="datePicker"
                        // style={{ width: "300px" }}
                        name={"cargoDate"}
                        // selected={value ? new Date(value) : null}
                        selected={convertUTCToLocalDate(formData["cargoDate"])}
                        onChange={(date) => onChangeDate(date, "cargoDate")}
                        dateformat="dd/MM/yyyy" // Customize date format if needed
                        placeholderText="Select a date"
                        showMonthDropdown
                        showYearDropdown
                        customInput={
                          <MaskedTextInput
                            type="text"
                            dateformat="dd/mm/yyyy"
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            onKeyPress={preventEnterKey}
                          />
                        }
                        // showPopperArrow={true}
                        disabled={isDisabled && disableAfterSelect}
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
      {!firstRow && !secondRow && (
        <Col>
          <Form.Group>
            <Form.Label>{labelName}</Form.Label>
            <div className="input-with-icon">
              {!isCurrentTimeInput && !isDateInput && (
                <Form.Control
                  type="text"
                  name={inputName}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  // onFocus={handleInputFocus}
                  // onBlur={handleInputBlur}
                  // required={inputName === "shipNo"}
                  // maxLength={200}
                  style={{ width: "100%" }}
                  disabled={disabled || (isDisabled && disableAfterSelect)}
                  // onBlur={handleShowSuggestions}
                  // onKeyDown={handleKeyDown}
                  // onKeyPress={preventEnterKey}
                  autoComplete="off"
                />
              )}

              {isDateInput && (
                <div className="date-picker-wrapper">
                  <DatePicker
                    // labelName={"E.T.D."}
                    className="form-control"
                    wrapperClassName="datePicker"
                    // style={{ width: "300px" }}
                    name={inputName}
                    // selected={value ? new Date(value) : null}
                    selected={convertUTCToLocalDate(formData[inputName])}
                    onChange={(date) => onChange(date, inputName)}
                    dateformat="dd-MM-yyyy" // Customize date format if needed
                    placeholderText="Select a date"
                    showMonthDropdown
                    showYearDropdown
                    customInput={
                      <MaskedTextInput
                        type="text"
                        dateformat="dd-mm-yyyy"
                        mask={[
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        onKeyPress={preventEnterKey}
                      />
                    }
                    // showPopperArrow={true}
                    disabled={isDisabled && disableAfterSelect}
                    autoComplete="off"
                  />
                </div>
              )}

              {isCurrentTimeInput && (
                <div className="">
                  <TimePicker
                    className="form-control "
                    name={inputName}
                    value={value}
                    onChange={onChange}
                    clearIcon={null}
                    // clockIcon= {null}
                    isOpen={false}
                    disableClock={true}
                    // dateformat="dd-MM-yyyy"
                    placeholderText="Select the time"
                    // showMonthDropdown
                    // showYearDropdown
                    disabled={isDisabled && disableAfterSelect}
                  />
                </div>
              )}
              {/* {showButton1 && (
                <button
                  className="icon-btn-awbbl"
                  // data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                  style={{
                    border: "none",
                    background: "none",
                    outline: "none",
                  }}
                  onClick={(e) => handleShowModal1(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                    />
                  </svg>
                </button>
              )} */}
              {showButton2 && (
                <button
                  className="icon-btnn"
                  // data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                  style={{
                    border: "none",
                    background: "none",
                    outline: "none",
                  }}
                  onClick={(e) => handleShowModal2(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                    />
                  </svg>
                </button>
              )}
              {/* {showButton3 && (
                <button
                  className="icon-btn"
                  // data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"
                  style={{
                    border: "none",
                    background: "none",
                    outline: "none",
                  }}
                  onClick={(e) => handleShowModal3(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                    />
                  </svg>
                </button>
              )} */}
            </div>
          </Form.Group>

          <Modal show={showModal2} onHide={handleCloseModal2} style={{display:"flex", justifyContent:"center", alignItems:"center",  paddingTop:"130px"}}>
            <Modal.Header closeButton>
              <Modal.Title className="">Container#</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ width :"550px",}}>
              <form>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-1">
                    <label htmlFor="recipient-name" className="col-form-label">
                      containerNo2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerNo2"
                      // placeholder={placeholder}
                      value={formData["containerNo2"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      containerNo3
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerNo3"
                      // placeholder={placeholder}
                      value={formData["containerNo3"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      containerNo4
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerNo4"
                      // placeholder={placeholder}
                      value={formData["containerNo4"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      containerNo5
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerNo5"
                      // placeholder={placeholder}
                      value={formData["containerNo5"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  {/* <div className="">
                  <label htmlFor="message-text" className="col-form-label">
                    containerNo6
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    name="containerNo6"
                    // placeholder={placeholder}
                    value={formData["containerNo6"]}
                    onChange={onChange}
                  />
                </div> */}
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-1">
                    <label htmlFor="recipient-name" className="col-form-label">
                      containerSize2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerSize2"
                      // placeholder={placeholder}
                      value={formData["containerSize2"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      containerSize3
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerSize3"
                      // placeholder={placeholder}
                      value={formData["containerSize3"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      containerSize4
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerSize4"
                      // placeholder={placeholder}
                      value={formData["containerSize4"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      containerSize5
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="containerSize5"
                      // placeholder={placeholder}
                      value={formData["containerSize5"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  {/* <div className="">
                  <label htmlFor="message-text" className="col-form-label">
                    containerSize6
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    name="containerSize6"
                    // placeholder={placeholder}
                    value={formData["containerSize6"]}
                    onChange={onChange}
                  />
                </div> */}
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-1">
                    <label htmlFor="recipient-name" className="col-form-label">
                      volume2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="volume2"
                      // placeholder={placeholder}
                      value={formData["volume2"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      volume3
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="volume3"
                      // placeholder={placeholder}
                      value={formData["volume3"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      volume4
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="volume4"
                      // placeholder={placeholder}
                      value={formData["volume4"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <label htmlFor="message-text" className="col-form-label">
                      volume5
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      name="volume5"
                      // placeholder={placeholder}
                      value={formData["volume5"]}
                      onChange={onChange}
                      disabled={isDisabled && disableAfterSelect}
                      autoComplete="off"
                    />
                  </div>
                  {/* <div className="">
                  <label htmlFor="message-text" className="col-form-label">
                    volume6
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    name="volume6"
                    // placeholder={placeholder}
                    value={formData["volume6"]}
                    onChange={onChange}
                  />
                </div> */}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCloseModal2}
              >
                Confirm
              </button>
              {/* <button type="button" className="btn btn-primary">
              Confirm
            </button> */}
            </Modal.Footer>
          </Modal>
        </Col>
      )}
    </>
  );
};

export default InputBox;
