import React, { useContext, useEffect, useState ,useRef} from "react";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import "./ExportCsv.css";
import Header from "../../components/header";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";
import context from "../../context/context";
import { useCallback } from "react";
import { debounce, filter } from "underscore";
import CsvRow from "./CsvRow";


const ExportCsv = () => {
  const [loading, setLoading] = useState(true);
  const [filteredEntries, setFilteredEntries] = useState([]);
  // const [isInputHilite, setIsInputHilite] = useState(false);

  const [totalEntries, setTotalEntries] = useState([]);
  const [sentEntries, setSentEntries] = useState([]);

  const [userForSearch, setUserForSearch] = useState("");
  const [shipNoForSearch, setShipNoForSearch] = useState("");
  const [lerrorNoForSearch, setlerrorNoForSearch] = useState("");

  const [isUpdateShipmentNo, setIsUpdateShipmentNo] = useState(false);

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [changedRowId, setChangedRowId] = useState(null); // for updating the table row only
  const [isVisibleArray, setIsVisibleArray] = useState([]); // for visible POs of shipment

  const ctx = useContext(context);
  const { userData } = useContext(context);
  const popupRef = useRef(null);

  const sendToSent = (shipno, i) => {
    //console.log(shipno);
    setFilteredEntries((prev) => prev.filter((el, i) => el.ShipNo !== shipno));
    //console.log("filterdone");
  };

  const { csvSent, setCsvSent, csvUnsent, setCsvUnsent } = ctx;

  // console.log("filter entreis", filteredEntries);
  const updateData = (id, newData) => {
    setFilteredEntries((prevData) =>
      prevData.map((row) => (row.ShipNo === id ? { ...row, ...newData } : row))
    );
    setChangedRowId(id);
  };

  const handleFilter = async (sent, unsent, pageNo, limit) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `/barcode/getTransferData?&sent=${sent.toString()}&unsent=${unsent.toString()}&csn=${lerrorNoForSearch}&ShipNo=${shipNoForSearch}&username=${userForSearch}&pageNo=${pageNo}&docNo=${limit}`
      );
      // console.log("ressssssssss", res);
      // ctx.setPageCountTransferData(Math.ceil(res.data.pageCount));
      // ctx.setCurrentPageTransferData(0);
      setFilteredEntries(res.data.transferData);
      // setLoading(false);
      setPage(pageNo);

      if (res.data.transferData.length < limit) {
        setHasMore(false);
      }
      else {
        setHasMore(true);
      }
      setInitialFetchDone(true);
    } catch (err) {
      // console.log(err);
    } finally {
      setLoading(false);
    }
  };
  //console.log('transfer data ', filteredEntries)

  useEffect(() => {
    // handleFilter(csvSent, csvUnsent, 0, 30);
    if (!initialFetchDone) {
      handleFilter(csvSent, csvUnsent, 0, 30);
      // setInitialFetchDone(true); // Set initialFetchDone to true after initial fetch
      return;
    }
    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");
      // console.log("use effect calling.");
      if (lastRow) {
        // console.log('last row calling in useEffect')
        // console.log("boxapi length", filteredEntries.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !loading
        ) {
          // console.log("api calling from useEffect");
          handleFilter(
            csvSent,
            csvUnsent,
            0,
            30 * ((filteredEntries.length + 30) / 30)
          );
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page, loading]);

  // useEffect(() => {
  //   // console.log("use effect running after ");
  //   console.log('am i called1??')
  //   handleFilter(csvSent, csvUnsent, 0, 30);
  //   // setLoading(true)
  // }, [csvSent, csvUnsent, isUpdateShipmentNo]);

  // console.log("sent check", csvSent);

  //   axios
  //     .get("/barcode/getTransferData?sent=true&unsent=true")
  //     .then((res) => {
  //       console.log("total Entries", res.data.transferData)
  //       setTotalEntries(res.data.transferData);
  //       const temp_tot_data = res.data.transferData
  //       // Make the second API call here, nested inside the first .then() block
  //       axios
  //         .get("/barcode/getTransferData?sent=true&unsent=false")
  //         .then((res) => {
  //           // temp_tot_data.filter((data)=> )
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [csvSent, csvUnsent]);

  // const handlePageClick = (e) => {
  //   // for pagination handle function
  //   setLoading(true);
  //   axios
  //     .get(
  //       `/barcode/getTransferData?pageNo=${
  //         e.selected
  //       }&docNo=${30}&sent=${csvSent.toString()}&unsent=${csvUnsent.toString()}&ShipNo=${shipNoForSearch}&username=${userForSearch}`
  //     )
  //     .then((res) => {
  //       //ctx.setPageCountTransferData(Math.ceil((res.data.entries.length/30)+1));
  //       ctx.setCurrentPageTransferData(e.selected);
  //       setFilteredEntries(res.data.transferData);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       // Handle error if the axios request fails
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     });
  // };

  const handleFilterForSearch = (
    e,
    shipNoForSearch,
    userForSearch,
    csvSent,
    csvUnsent,
    lerrorNoForSearch
  ) => {
    setLoading(true);
    const encodedShipNo = encodeURIComponent(shipNoForSearch);
    const encodedUser = encodeURIComponent(userForSearch);
    const encodedlerros = encodeURIComponent(lerrorNoForSearch);
    axios
      .get(
        `/barcode/getTransferData?sent=${csvSent.toString()}&unsent=${csvUnsent.toString()}&csn=${encodedlerros}&ShipNo=${encodedShipNo}&username=${encodedUser}&pageNo=${0}&docNo=${30}`
      )
      .then((res) => {
        // ctx.setPageCountTransferData(Math.ceil(res.data.pageCount));
        // ctx.setCurrentPageTransferData(0);
        setFilteredEntries(res.data.transferData);
        setLoading(false);
        // setHasMore(true);
        if (res.data.transferData.length < 30) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (
        e,
        shipNoForSearch,
        userForSearch,
        csvSent,
        csvUnsent,
        lerrorNoForSearch
      ) =>
        handleFilterForSearch(
          e,
          shipNoForSearch,
          userForSearch,
          csvSent,
          csvUnsent,
          lerrorNoForSearch
        ),
      1000,
      false
    ),
    []
  );

  const handleReset = () => {
    setLoading(true);
    setInitialFetchDone(false);
    axios
      .get(
        `/barcode/getTransferData?pageNo=${0}&docNo=${30}&sent=${csvSent.toString()}&unsent=${csvUnsent.toString()}`
      )
      .then((res) => {
        //  console.log("checking correct data or not", res);

        setShipNoForSearch("");
        setUserForSearch("");
        // ctx.setPageCountTransferData(Math.ceil(res.data.pageCount));
        // ctx.setCurrentPageTransferData(0);
        setFilteredEntries(res.data.transferData);
        if (res.data.transferData.length < 30) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };
  // console.log("filter entries", filteredEntries);
  // console.log('checking sent csv or not ', csvSent)
  // console.log('unsent checking', csvUnsent)




   // muti PO popup function when outside the click then close automaitic
  
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisibleArray([]); // Close all popups
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div>
      <div className="fixed-top">
        <div>
          <Header />
        </div>
        <div
          className="d-flex justify-content-start"
          style={{
            // border: "2px solid red",
            width: "100%",
            padding: "0% 1% 1% 2%",
            background: "#fff",
          }}
        >
          {!!userData.admin && (
            <div
              // className="d-flex row "
              style={{
                textTransform: "capitalize",
                // border: "2px solid red",
                width: "16%",
                display: "flex",
                flexDirection: "column",
                paddingTop: ".9%",
              }}
            >
              <div
                className="p-2"
                style={{
                  // border: "2px solid green",
                  height: "5.4vh",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: ".1%",
                }}
              >
                <label className="fs-6 mb-0">User Name</label>
              </div>
              <input
                size="sm"
                type="Search"
                name="user"
                className="ms-2 form-control form-control-sm"
                // required
                placeholder="Username"
                maxLength={10}
                // id="input-search"
                value={userForSearch}
                onChange={(e) => {
                  setUserForSearch(e.target.value);
                  OnChangeHandleFilter(
                    e,
                    shipNoForSearch,
                    // csnForSearch,
                    e.target.value,
                    csvSent,
                    csvUnsent,
                    lerrorNoForSearch
                  );
                }}
                style={{
                  border: "2px solid #000",
                  fontWeight: "bold",
                  width: "200px",
                  hright: "4vh",
                }}
                autoComplete="off"
              />
            </div>
          )}
          <div
            style={{
              // border: "2px solid green",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "480px",
              paddingTop: ".5%",
            }}
          >
            {" "}
            <div
              className="d-flex row "
              style={{
                textTransform: "capitalize",
                // border: "2px solid red",
                width: "50%",
              }}
            >
              <div className="p-2">
                <label
                  className="fs-6 mb-0"
                  style={{
                    width: "70%",
                    // border: "2px solid blue"
                  }}
                >
                  Factory Shipment
                </label>
              </div>
              <input
                size="sm"
                type="Search"
                // id="inputh"
                className="ms-2 form-control form-control-sm"
                name="shipment"
                value={shipNoForSearch}
                placeholder="Factory Shipment"
                minLength={5}
                maxLength={20}
                onChange={(e) => {
                  setShipNoForSearch(e.target.value);
                  OnChangeHandleFilter(
                    e,
                    e.target.value,
                    // csnForSearch,
                    userForSearch,
                    csvSent,
                    csvUnsent,
                    lerrorNoForSearch
                  );
                }}
                style={{
                  border: "2px solid #000",
                  fontWeight: "bold",
                  width: "200px",
                  textTransform: "UpperCase",
                }}
                autoComplete="off"
              />
            </div>
            <div
              className="d-flex row "
              style={{
                textTransform: "capitalize",
                // border: "2px solid blue",
                width: "50%",
              }}
            >
              <div className="p-2">
                <label
                  className="fs-6 mb-0"
                  style={{
                    width: "70%",
                    // border: "2px solid blue",
                  }}
                >
                  Lerros Shipment
                </label>
              </div>
              <input
                size="sm"
                type="Search"
                // id="inputh"
                className="ms-2 form-control form-control-sm"
                name="shipment"
                value={lerrorNoForSearch}
                placeholder="Lerros Shipment"
                minLength={5}
                maxLength={8}
                onChange={(e) => {
                  const value = e.target.value;
                  setlerrorNoForSearch(value.replace(/[^0-9]/g, ""));
                  OnChangeHandleFilter(
                    e,
                    shipNoForSearch,
                    userForSearch,
                    csvSent,
                    csvUnsent,
                    value
                  );
                }}
                style={{
                  border: "2px solid #000",
                  fontWeight: "bold",
                  width: "200px",
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div>
            <button
              // style={{ width: "80px" }}
              className="btn btn-primary mt-5 "
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleFilterForSearch(
                  e,
                  shipNoForSearch,
                  userForSearch,
                  csvSent,
                  csvUnsent,
                  lerrorNoForSearch
                );
              }}
            >
              Filter
            </button>
          </div>
          <div>
            <button
              className="ms-3 btn btn-danger mt-5"
              type="button"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
          <div className="mt-5 ms-5">
            <input
              className="form-check-input me-2 mt-2"
              size={"large"}
              type="checkbox"
              value={csvUnsent}
              checked={csvUnsent}
              onChange={(e) => {
                setCsvUnsent((prev) => !prev);
                setInitialFetchDone(false);
                OnChangeHandleFilter(
                  e,
                  shipNoForSearch,
                  userForSearch,
                  csvSent,
                  e.target.checked,
                  lerrorNoForSearch
                );
              }}
              label=" UnSentCsv"
            />
            <label className="fw-bold fs-5">UnsentCsv</label>
          </div>
          <div className="mt-5 ms-5">
            <input
              className="form-check-input me-2 mt-2"
              type="checkbox"
              checked={csvSent}
              value={csvSent}
              onChange={(e) => {
                setCsvSent((prev) => !prev);
                setInitialFetchDone(false);
                OnChangeHandleFilter(
                  e,
                  shipNoForSearch,
                  userForSearch,
                  e.target.checked,
                  csvUnsent,
                  lerrorNoForSearch
                );
              }}
              label="SentCsv"
            />
            <label className="fw-bold fs-5">SentCsv</label>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "200px" }}>
        {loading && !initialFetchDone ? (
          <Loader />
        ) : filteredEntries && filteredEntries.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>No Records Found!</h3>
        ) : (
          <div>
            <div className="mx-4 mt-1 table-responsive tableFixHead table-container">
              <table className="table table-bordered  table-hover  table-sm table-header-for-all">
                <thead>
                  <tr className="table-warning">
                    <th>S.No.</th>
                    <th>User Name</th>
                    <th>Factory Shipment</th>
                    <th>Lerros Shipment</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Csv/ShipInfo</th>
                    {/* <th>Barcode</th> */}
                    <th>Excel</th>
                    {/* <th>ShipInfo</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredEntries.map((entry, index) => {
                    // console.log("index ", index);
                    //  console.log('entry entry', entry)
                    return (
                      <CsvRow
                        key={entry.ShipNo}
                        popupRef={popupRef}
                        index={index}
                        entry={entry}
                        sendToSent={sendToSent}
                        setCsvUnsent={setCsvUnsent}
                        setCsvSent={setCsvSent}
                        csvSent={csvSent}
                        csvUnsent={csvUnsent}
                        setIsUpdateShipmentNo={setIsUpdateShipmentNo}
                        updateData={updateData} // callback for update the table row
                        isChanged={entry.ShipNo === changedRowId} // for checking it should update or not
                        handleFilter={handleFilter}
                        isVisibleArray={isVisibleArray}
                        setIsVisibleArray={setIsVisibleArray}
                      />
                    );
                  })}
                </tbody>
              </table>
              {loading && initialFetchDone && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {/* {!loading && !hasMore && <div>No more data</div>} */}
              {/* {!!ctx.pageCountTransferData && (
                <ReactPaginate //ctx.pageCountPoSummary &&
                  disableInitialCallback={true}
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={ctx.pageCountTransferData}
                  forcePage={ctx.currentPageTransferData}
                  // initialPage={ctx.currentPage}
                  previousLabel="< prev"
                  renderOnZeroPageCount={null}
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                />
              )} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExportCsv;
