import axios from "axios";
import "./EditUser.css";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header";
import { useContext, useEffect, useState, useRef } from "react";
import Context from "../../../context/context";
import toast from "react-hot-toast";
import ErrosToast from "../../../components/ErrosToast";

const EditUser = () => {
  const inputRef = useRef(null); // Ref to track the dropdown

  const ctx = useContext(Context);
  const { blankUserState } = useContext(Context);

  const [userData, setUserData] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("userData"));
    return savedData !== null ? savedData : blankUserState;
  });

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [barcodeRanges, setBarcodeRanges] = useState([]);
  const [selectedBR, setSBR] = useState(userData?.barcodeRangeId || "select");

  const [showPassword, setShowPassword] = useState(false);

  const [factNames, setFactNames] = useState([]);
  const [filteredFactSuggestions, setFilteredFactSuggestions] =
    useState(factNames);
  const [countryFactory, setCountryFactory] = useState([]);
  const [selectedFactories, setSelectedFactories] = useState([]);
  const [currentFactory, setCurrentFactory] = useState("");
  const inputRefForFactory = useRef(null);
  const containerRefForFactory = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const [supNames, setSupNames] = useState([]);
  const [filteredSupSuggestions, setFilteredSupSuggestions] =
    useState(supNames);

  const [agentNames, setAgentNames] = useState([]);
  const [filteredAgentSuggestions, setFilteredAgentSuggestions] =
    useState(agentNames);

  const [totalCountry, setTotalCountry] = useState([]);
  const [countryName, setCountryName] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    axios.get("/po/getPoSummary").then((result) => {
      const uniqueFacts = [];
      const uniqueSup = [];
      const uniqueAgents = [];
      result.data.purchaseOrders.filter((data) => {
        if (!uniqueFacts.includes(data.FACT) && data.FACT !== null) {
          uniqueFacts.push(data.FACT);
        }
        if (!uniqueSup.includes(data.SUP) && data.SUP !== null) {
          uniqueSup.push(data.SUP);
        }
        if (!uniqueAgents.includes(data.AGNT) && data.AGNT !== null) {
          uniqueAgents.push(data.AGNT);
        }
      });
      setFactNames(uniqueFacts);
      setSupNames(uniqueSup);
      setAgentNames(uniqueAgents);
      //setFilteredFactSuggestions(factNames);
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/admin");
    }

    if (id) {
      axios
        .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getUser?id=" + id)
        .then((res) => {
          console.log("data from getUser", res.data);
          setUserData({
            id: res.data.user.id, // for comparing the current user and updated user in modifyUser.
            username: res.data.user.username,
            password: res.data.user.password,
            supplier: res.data.user.supplier,
            factory: res.data.user.factory
              ? res.data.user.factory.split(",")
              : "",
            barcodeRangeId: res.data.user.barcodeRangeId,
            selectedBR: res.data.user.barcodeRangeId,
            user: res.data.user.user ? (res.data.user.subAdmin === true ? false : true ) : false,
            company: res.data.user.company,
            agent: res.data.user.agent,
            admin: res.data.user.admin ? (res.data.user.subAdmin === true ? false : true ) : false,
            dsp: res.data.user.dsp,
            boxDetails: res.data.user.boxDetails,
            shipInfo: res.data.user.shipInfo,
            entry: res.data.user.entry,
            entryModification: res.data.user.entryModification,
            csv: res.data.user.csv,
            orders: res.data.user.orders,
            createUser: res.data.user.createUser,
            modifyUser: res.data.user.modifyUser,
            loadPO: res.data.user.loadPO,
            boxType: res.data.user.boxType,
            rangeCode: res.data.user.rangeCode,
            // others: res.data.user.others,
            barcodePdf: res.data.user.barcodePdf,
            exFactory: res.data.user.exFactory,
            shipmentLoading: res.data.user.shipmentLoading,
            defaultOvership: res.data.user.defaultOvership,
            overshipAccess: res.data.user.overshipAccess ? true : false,
            readOnlyOvershipAccess: res.data.user.readOnlyOvershipAccess
              ? true
              : false,
            etdAccess: res.data.user.etdAccess,
            readOnlyEtdAccess: res.data.user.readOnlyEtdAccess ,
            reports: res.data.user.reports,
            deletePoEnteriesAccess: res.data.user.deletePoEnteriesAccess,
            subAdmin: res.data.user.subAdmin ? true : false,
            closePO: res.data.user.closePO ? true : false,
            countryName: res.data.user.countryName
          });
          setSBR(res.data.user.barcodeRangeId);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      setUserData(blankUserState);
    }

    axios
      .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getAllBarcodeRanges")
      .then(({ data }) => {
        // console.log("barcodessssss", data.barcodeRanges);
        setBarcodeRanges(data.barcodeRanges);
        // setSBR();
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [id, navigate, blankUserState]);

  // console.log("user data in modify", userData);

  const UpdateUser = (e) => {
    e.preventDefault();
    if (userData === "") {
      toast.remove();
      return ErrosToast("Please Fill the form");
    }

    if (
      userData.username === "" &&
      userData.password === "" &&
      userData.company === ""
    ) {
      toast.remove();
      return ErrosToast("Please Enter Highlighted Field");
    }

    if (userData.username === "") {
      toast.remove();
      return ErrosToast("Please Provide User Name");
    }

    if (userData.password === "") {
      toast.remove();
      return ErrosToast("Please Provide Password");
    }
    if (userData.company === "") {
      toast.remove();
      return ErrosToast("Please Give Company Name");
    }

    if (userData.entry && selectedBR === "select") {
      toast.remove();
      return ErrosToast("Barcode range should be there.");
    }
    if (!userData.admin && !userData.user && !userData.subAdmin) {
      return ErrosToast("Please selct any of role User or Admin");
    }
    if (selectedBR === "select" && !userData.admin) {
      toast.remove();
      return ErrosToast("Select Barcode Range");
    }

    if (
      (userData.user === true && userData.subAdmin !== true) &&
      userData.factory.length === 0 &&
      userData.agent === "" &&
      userData.supplier === ""
    ) {
      return ErrosToast(
        "For create user, one should have to be there from factory or agent or supplier."
      );
    }
    if(userData.subAdmin === true && (userData.admin === true || userData.user === true)){
      toast.remove();
      return ErrosToast("For sub-admin, admin or user can not be true");
    }

    if(userData.etdAccess === true && userData.readOnlyEtdAccess){
      toast.remove();
      return ErrosToast("Both (Display Update ETD) can not be true together!");
    }

    if(userData.overshipAccess === true && userData.readOnlyOvershipAccess){
      toast.remove();
      return ErrosToast("Both (Display and Update PO Overship) can not be true together!");
    }

    if((userData.user === true && userData.subAdmin === false && userData.factory.length > 1)){
      toast.remove();
      return ErrosToast("User can not have multiple factories.")
    }
    if((userData.admin === true && userData.subAdmin === false && userData.factory.length > 0)){
      toast.remove();
      return ErrosToast("Admin can not have multiple factories.")
    }

    // if(userData.subAdmin === true){
    //   userData.admin = true;
    //   userData.user = true;
    // }
    let updatedUserData =
      userData.factory && userData.factory.length > 1
        ? { ...userData, factory: userData.factory.join(",") }
        : { ...userData, factory: userData.factory.toString() || "" };

    updatedUserData = updatedUserData.subAdmin === true ? {...updatedUserData, admin: true, user: true} : updatedUserData;
    
    // return toast.error('no wayyyy!')
    axios
      .post(process.env.REACT_APP_API_LINK + "/api/v1/admin/updateUser", {
        userData: updatedUserData,
        id,
      })
      .then((res) => {
        if (ctx.userData.id === parseInt(id)) {
          //this is the condition for updating the userData if the same user is updated in which
          ctx.setUserData(res.data.userData); // we are logged in.
        } else {
          // if we are not updating the user in which we are not logged in
          const updatedUserLists = ctx.userlist.map((user) =>
            user.id === id ? res.data.userData : user
          );
          ctx.setUserlist(updatedUserLists);
        }
        toast.remove();
        toast.success("User Data updated successfully!");
        localStorage.removeItem("modifyData");
        setUserData(blankUserState);
        navigate("/ModifyUser");
      });
  };

  // console.log("user data from edit user", userData);
  // console.log("context userdata", ctx.userData);
  // console.log("user lists", ctx.userlist);
  // console.log('user data id', ctx.userData.id)
  // console.log('id from url', id)
  // console.log('compare 2 numbers', ctx.userData.id===parseInt(id))

  useEffect(() => {
    // for saving the data if we switch to another tab
    const savedData = JSON.parse(localStorage.getItem("modifyData"));
    if (savedData) {
      setUserData(savedData);
    }
  }, []);

  // Close dropdown if clicked outside
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("modifyData", JSON.stringify(userData));
  }, [userData]);

  const handleDelete = (e) => {
    e.preventDefault();
    navigate("/ModifyUser");
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    if (e.target.name === "factory") {
      setCurrentFactory(userInput);
      setUserData({
        ...userData,
        factory: [...selectedFactories, userInput],
      });
    } else {
      setUserData({
        ...userData,
        [e.target.name]: userInput.trim().toUpperCase(),
      });
    }
    if (e.target.name === "factory") {
      setCurrentFactory(userInput);
      const filtered = (
        countryFactory.length > 0 ? countryFactory : factNames
      ).filter(
        (fact, index) =>
          fact.toLowerCase().startsWith(userInput.toLowerCase()) &&
          !userData.factory.includes(fact) // Exclude already selected factories
      );
      setFilteredFactSuggestions(filtered);
    }
    if (e.target.name === "supplier") {
      const filterd = supNames.filter((sup, index) =>
        sup.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredSupSuggestions(filterd);
    }
    if (e.target.name === "agent") {
      const filterd = agentNames.filter((agent, index) =>
        agent.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredAgentSuggestions(filterd);
    }

    //console.log(userInput)
  };

  const handleSuggestionSelect = (selectSuggestion) => {
    setFilteredFactSuggestions([]);
  };

  // NEW BARCODE RANGE

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    if (inputRef.current) {
      inputRef.current.style.border = " 0 0 10px rgba(135, 206, 250, 0.7)";
      // Thicker sky blue border
      inputRef.current.style.backgroundColor = "#e0f7ff"; // Light bluish background color
      inputRef.current.style.boxShadow = "0 0 10px rgba(135, 206, 250, 0.7)"; // Bluish shadow
    }
    setIsOpen(!isOpen);
  };

  // Close the dropdown if clicking outside of it
  const handleClickOutside = (event) => {
    // Close dropdown if click is outside the container
    if (
      containerRefForFactory.current &&
      !containerRefForFactory.current.contains(event.target)
    ) {
      setFilteredFactSuggestions([]);
    }
  };

  // Handle the selection of an option
  const handleSelect = (value) => {
    setSBR(value); // Set the selected barcode range
    setUserData({
      ...userData,
      barcodeRangeId: value === "select" ? null : value,
    }); // Update userData with selected value
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleInputFocus = () => {
    // Show all suggestions on focus (excluding already selected factories)
    const filtered = (
      countryFactory.length > 0 ? countryFactory : factNames
    ).filter((fact) => !userData.factory.includes(fact));
    setFilteredFactSuggestions(filtered);
  };

  useEffect(() => {
    fetchCountryData("");
  }, []);

  const fetchCountryData = async (countryCode) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_LINK}/api/v1/po/getLocation?cntry=${countryCode}`
      );

      // Update state with response data
      if (res.data) {
        setTotalCountry(res.data.countryArray || []); // Update country list
        if (countryCode === "") {
          // If no country selected, set all fact suggestions
          setFilteredFactSuggestions(factNames);
          setCountryFactory(factNames)
        } else if (res.data.factArray.length > 0) {
          setFilteredFactSuggestions(res.data.factArray); // Update filtered facts
          setCountryFactory(res.data.factArray);
        }
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    console.log('selected country', selectedCountry)
    setCountryName(selectedCountry); // Update selected country in state
    setUserData({...userData, countryName: selectedCountry=== "" ? null: selectedCountry, factory: []})

    if (selectedCountry === "") {
      // Fetch all countries if no country is selected
      fetchCountryData("");
    } else {
      // Fetch specific country data
      fetchCountryData(selectedCountry.substring(0, 2));
    }
  };

  const handleAddFactory = (fact) => {
    if (!selectedFactories.includes(fact) && fact.trim() !== "") {
      setSelectedFactories([...selectedFactories, fact]);
    }
    setUserData({ ...userData, factory: [...userData.factory, fact] }); // Clear input after adding
    setCurrentFactory(""); // Clear input after adding
    setFilteredFactSuggestions(
      (countryFactory.length > 0 ? countryFactory : factNames).filter(
        (f) => !userData.factory.includes(f) && f !== fact
      )
    ); // Update suggestions
  };

  const handleRemoveFactory = (fact) => {
    // setSelectedFactories(selectedFactories.filter((item) => item !== fact));
    // Remove the factory from the selected list
    const updatedFactories = userData.factory.filter((f) => f !== fact);
    setUserData({
      ...userData,
      factory: updatedFactories,
    });

    // Update suggestions
    setFilteredFactSuggestions(
      (countryFactory.length > 0 ? countryFactory : factNames).filter(
        (f) => !updatedFactories.includes(f)
      )
    );
  };

  return (
    <div className="full-page">
      <div className="sticky-top">
        <Header />
      </div>

      <div className="container my-4 layout">
        <form
          className="row g-3"
          // onSubmit={!id ? createUser : UpdateUser}
          autoComplete="off"
        >
          {/* <div className="Auth-form-content"> */}
          <h3 className="Auth-form-title">Modify User</h3>

          {/* <div className="row"> */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Username*</label>
              <input
                type="text"
                required
                className="form-control mt-1 "
                placeholder="Enter Username"
                name="username"
                maxLength={20}
                value={userData.username}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    [e.target.name]: e.target.value.trim(),
                  })
                }
                autoComplete="off"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Password*</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control mt-1"
                  placeholder="Enter password"
                  name="password"
                  required
                  maxLength={10}
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      [e.target.name]: e.target.value.trim(),
                    })
                  }
                  autoComplete="off"
                />

                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    width: "13%",
                    height: "70%",
                    border: "none",
                    background: "transparent",
                  }}
                  className="btn btn-sm btn-secondary mt-1"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? (
                    <i
                      className="fas fa-eye-slash"
                      style={{ color: "black" }}
                    ></i>
                  ) : (
                    <i className="fas fa-eye" style={{ color: "black" }}></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}

          {/* <div className="row"> */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Company Name:</label>
              <input
                type="text"
                className="form-control mt-1 "
                placeholder="Enter Company"
                name="company"
                maxLength={10}
                required
                value={userData.company}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    [e.target.name]: e.target.value.trim(),
                  })
                }
                autoComplete="off"
              />
            </div>
          </div>

          <div className="col-md-6">
            <label>Country Name:</label>
            <select
              className="form-select mt-1"
              aria-label="Default select"
              onChange={(e) => handleCountryChange(e)}
              value={countryName || userData.countryName}
            >
              <option value={""}>Select country</option>
              {totalCountry.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="col-md-6">
                <div className="form-group">
                  <label>Factory:</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter Factory"
                    name="factory"
                    maxLength={5}
                    value={userData.factory}
                    onChange={handleInputChange}
                    list="FactSuggestions"
                    autoComplete="off"
                  />
                  <datalist id="FactSuggestions">
                    {filteredFactSuggestions.map((fact, index) => (
                      <option
                        key={index}
                        onClick={() => handleSuggestionSelect(fact)}
                        value={fact}
                      >
                        {fact}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div> */}

          <div className="col-md-6">
            <label>Factory</label>
            <div className="position-relative" ref={containerRefForFactory}>
              {/* Input Box with Selected Factories */}
              <div
                className="form-control mt-1 d-flex align-items-center flex-wrap"
                style={{
                  minHeight: "40px",
                  cursor: "text",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                }}
                // onClick={() => inputRef.current.focus()}
              >
                {/* Render Selected Factories */}
                {userData.factory &&
                  userData.factory.map((fact, index) => (
                    <span
                      key={index}
                      className="badge bg-primary me-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveFactory(fact)}
                    >
                      {fact} &times;
                    </span>
                  ))}

                {/* Typing Input */}
                <input
                  ref={inputRefForFactory}
                  id="factory-input"
                  type="text"
                  className="border-0 flex-grow-1 "
                  style={{
                    outline: "none",
                    boxShadow: "none",
                    padding: "2px 0",
                    width: "auto",
                    minWidth: "100px",
                  }}
                  placeholder={
                    userData.factory.length === 0 ? "Enter Factory..." : ""
                  }
                  name="factory"
                  value={currentFactory}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (
                        activeIndex >= 0 &&
                        filteredFactSuggestions[activeIndex]
                      ) {
                        handleAddFactory(filteredFactSuggestions[activeIndex]);
                      } else {
                        handleAddFactory(currentFactory);
                      }
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      setActiveIndex((prev) =>
                        prev < filteredFactSuggestions.length - 1 ? prev + 1 : 0
                      );
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      setActiveIndex((prev) =>
                        prev > 0 ? prev - 1 : filteredFactSuggestions.length - 1
                      );
                    }
                  }}
                />
              </div>

              {/* Suggestions Dropdown */}
              {filteredFactSuggestions.length > 0 && (
                <ul
                  className="dropdown-menu show position-absolute w-100"
                  style={{
                    zIndex: 1000,
                    maxHeight: "180px",
                    overflowY: "auto", // Enable vertical scrolling
                  }}
                >
                  {filteredFactSuggestions.map((fact, index) => (
                    <li
                      key={index}
                      // className="dropdown-item"
                      className={`dropdown-item ${
                        index === activeIndex ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAddFactory(fact)}
                    >
                      {fact}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Supplier:</label>
              <input
                type="text"
                className="form-control mt-1"
                placeholder="Enter Supplier"
                name="supplier"
                maxLength={5}
                value={userData.supplier}
                onChange={handleInputChange}
                list="SupSuggestions"
                autoComplete="off"
              />
              <datalist id="SupSuggestions">
                {filteredSupSuggestions.map((sup, index) => (
                  <option
                    key={index}
                    onClick={() => handleSuggestionSelect(sup)}
                    value={sup}
                  >
                    {sup}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="col-md-6">
            <label>Agent:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Agent"
              name="agent"
              maxLength={5}
              value={userData.agent}
              onChange={handleInputChange}
              list="agentSuggestions"
              autoComplete="off"
            />
            <datalist id="agentSuggestions">
              {filteredAgentSuggestions.map((agent, index) => (
                <option
                  key={index}
                  onClick={() => handleSuggestionSelect(agent)}
                  value={agent}
                >
                  {agent}
                </option>
              ))}
            </datalist>
          </div>

          <div className="col-md-6">
            <label style={{ marginRight: "3%" }}>Barcode Range:</label>
            <div
              className="custom-select"
              style={{ position: "relative", width: "250px" }}
              tabIndex={0} // Makes the div focusable
              onBlur={() => setIsOpen(false)} // Close dropdown on blur (when focus is lost)
            >
              {/* Select Box */}
              <div
                className="select-box"
                style={{
                  border: "2px solid #000",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={toggleDropdown}
              >
                {selectedBR === "select" || !selectedBR
                  ? "Select"
                  : `${
                      barcodeRanges.find((x) => x.id === selectedBR)?.id ||
                      "Select"
                    }`}
              </div>

              {/* Options Container (conditionally rendered based on isOpen state) */}
              {isOpen && (
                <div
                  className="options-container"
                  style={{
                    border: "1px solid #ccc",
                    maxHeight: "200px",
                    overflowY: "auto",
                    position: "absolute",
                    width: "100%",
                    zIndex: 1000,
                    backgroundColor: "#fff",
                  }}
                >
                  {/* Default 'Select' option */}
                  <div
                    className="option"
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelect("select")}
                  >
                    Select
                  </div>

                  {/* Barcode ranges as options */}
                  {barcodeRanges &&
                    barcodeRanges.map((bt) => (
                      <div
                        className="option"
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          color: bt.usernames.length > 0 ? "brown" : "",
                        }}
                        key={bt.id}
                        onClick={() => handleSelect(bt.id)}
                      >
                        {/* Check for the selected value */}
                        {selectedBR === bt.id ? (
                          <div>{bt.id}</div>
                        ) : (
                          <div>{`${bt.id}: ${bt.usernames}`}</div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          {/* <div className="my-3 d-flex align-items-center"> */}

          <div className="col-md-6 d-flex align-items-center">
            <label>Overship percentage:</label>
            <input
              type="text"
              className="ms-2 form-control-md"
              style={{ width: "40px", borderRadius: "4px" }}
              name="defaultOvership"
              value={userData.defaultOvership}
              onChange={handleInputChange}
              placeholder="%"
              maxLength={3}
              autoComplete="off"
            />
            %
          </div>

          <div className="col-md-6">
            <div className="form-check">
              <input
                id="readOnlyOvershipAccess"
                type="checkbox"
                className="form-check-input"
                name="readOnlyOvershipAccess"
                value={userData.readOnlyOvershipAccess}
                checked={userData.readOnlyOvershipAccess}
                onChange={(e) => {
                  setUserData((prev) => {
                    return {
                      ...prev,
                      [e.target.name]: e.target.checked,
                      // overshipAccess: false,
                    };
                  });
                }}
              />
              <label class="form-check-label" htmlFor="flexRadioDefault1">
                Display PO Overship
              </label>
            </div>
            <div className="form-check">
              <input
                id="overshipAccess"
                type="checkbox"
                className="form-check-input"
                name="overshipAccess"
                value={userData.overshipAccess}
                checked={userData.overshipAccess}
                onChange={(e) => {
                  setUserData((prev) => {
                    return {
                      ...prev,
                      [e.target.name]: e.target.checked,
                      // readOnlyOvershipAccess: false,
                    };
                  });
                }}
              />
              <label
                className="form-check-label"
                htmlFor="poOvershipCheckbox" // Added htmlFor to associate label with checkbox
                onClick={(e) => e.preventDefault()}
              >
                Update PO overship
              </label>
            </div>
          </div>

          {/* <div className="col-md-2 ms-3">
              <input
                id="etdCheckbox"
                type="checkbox"
                className="me-2 form-check-input"
                name="etdAccess"
                value={userData.etdAccess}
                checked={userData.etdAccess}
                onChange={(e) => {
                  setUserData((prev) => {
                    return {
                      ...prev,
                      [e.target.name]: e.target.checked,
                    };
                  });
                }}
              />
              <label
                className="form-check-label"
                htmlFor="etdCheckbox" // Added htmlFor to associate label with checkbox
                onClick={(e) => e.preventDefault()}
              >
                ETD
              </label>
            </div> */}

          <div className="col-md-4 ">
            <div class="form-check">
              <input
                id="etdCheckbox"
                type="checkbox"
                className="form-check-input"
                name="readOnlyEtdAccess"
                value={userData.readOnlyEtdAccess}
                checked={userData.readOnlyEtdAccess}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.checked,
                    // etdAccess: false,
                  }));
                }}
              />
              <label
                class="form-check-label"
                htmlFor="flexRadioDefault1"
                onClick={(e) => e.preventDefault()}
              >
                Display ETD
              </label>
            </div>
            <div class="form-check">
              <input
                id="etdCheckbox"
                type="checkbox"
                className="form-check-input"
                name="etdAccess"
                value={userData.etdAccess}
                checked={userData.etdAccess}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.checked,
                    // readOnlyEtdAccess: false,
                  }));
                }}
              />
              <label class="form-check-label" htmlFor="flexRadioDefault2">
                Update ETD
              </label>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center pt-2">
            <input
              id="deletePoEnteriesAccess"
              type="checkbox"
              className="me-2 form-check-input"
              name="deletePoEnteriesAccess"
              value={userData.deletePoEnteriesAccess}
              checked={userData.deletePoEnteriesAccess}
              onChange={(e) => {
                setUserData((prev) => {
                  return {
                    ...prev,
                    [e.target.name]: e.target.checked,
                  };
                });
              }}
            />
            <label
              className="form-check-label"
              htmlFor="deletePoEnteriesCheckbox" // Added htmlFor to associate label with checkbox
              onClick={(e) => e.preventDefault()}
            >
              Delete poEntries
            </label>
          </div>

          <div className="col-md-4 d-flex justify-content-center pt-2">
            <input
              id="closePO"
              type="checkbox"
              className="me-2 form-check-input"
              name="closePO"
              value={userData.closePO}
              checked={userData.closePO}
              onChange={(e) => {
                setUserData((prev) => {
                  return {
                    ...prev,
                    [e.target.name]: e.target.checked,
                  };
                });
              }}
            />
            <label
              className="form-check-label"
              htmlFor="closePO" // Added htmlFor to associate label with checkbox
              onClick={(e) => e.preventDefault()}
            >
              Close PO
            </label>
          </div>
          {/* </div> */}
          {/* ctrl x */}

          <div className="row mt-3">
            <div className="col-md-4">
              {/* <div className=" form-check-lg"> */}
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  name="admin"
                  value={userData.admin}
                  checked={userData.admin}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                        dsp: prev.user || !prev.admin || e.target.checked,
                        loadPO: prev.user || !prev.admin || e.target.checked,
                        orders: prev.user || !prev.admin || e.target.checked,
                        boxDetails:
                          prev.user || !prev.admin || e.target.checked,
                        // others: prev.user || !prev.admin || e.target.checked,
                        reports: prev.user || !prev.admin || e.target.checked,
                        csv: prev.user || !prev.admin || e.target.checked,
                        createUser: e.target.checked,
                        modifyUser: e.target.checked,
                        rangeCode: e.target.checked,
                        // orders: e.target.checked,
                        shipmentLoading:
                          prev.user || !prev.admin || e.target.checked,
                        exFactory: prev.user || !prev.admin || e.target.checked,
                        barcodePdf:
                          prev.user || !prev.admin || e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Admin
                </label>
              {/* </div> */}
            </div>
            <div className="col-lg-4">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  name="user"
                  value={userData.user}
                  checked={userData.user}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                        dsp: !prev.user || prev.admin || e.target.checked,
                        //dsp: prev.dsp || e.target.checked,
                        loadPO: !prev.user || prev.admin || e.target.checked,
                        orders: !prev.user || prev.admin || e.target.checked,
                        boxDetails:
                          !prev.user || prev.admin || e.target.checked,
                        // others: !prev.user || prev.admin || e.target.checked,
                        reports: !prev.user || prev.admin || e.target.checked,
                        shipInfo: e.target.checked,
                        entry: e.target.checked,
                        entryModification: e.target.checked,
                        csv: !prev.user || prev.admin || e.target.checked,
                        // orders: e.target.checked,
                        boxType: e.target.checked,
                        shipmentLoading:
                          !prev.user || prev.admin || e.target.checked,
                        exFactory: !prev.user || prev.admin || e.target.checked,
                        barcodePdf:
                          !prev.user || prev.admin || e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  User
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <input
                //id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                name="subAdmin"
                value={userData.subAdmin}
                checked={userData.subAdmin}
                onChange={(e) =>
                  setUserData((prev) => {
                    return {
                      ...prev,
                      [e.target.name]: e.target.checked,
                      dsp: !prev.subAdmin || prev.user || prev.user || prev.admin || e.target.checked,
                      orders: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      //dsp: prev.dsp || e.target.checked,
                      loadPO: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      boxDetails: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      // others: !prev.user || prev.admin || e.target.checked,
                      reports: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      entry: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      // shipInfo: e.target.checked,
                      // entry: e.target.checked,
                      // entryModification: e.target.checked,
                      // csv: !prev.user || prev.admin || e.target.checked,
                      // orders: e.target.checked,
                      // dsp: e.target.checked,
                      // boxType: e.target.checked,
                      shipmentLoading:
                      !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      exFactory: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                      barcodePdf: !prev.subAdmin || prev.user || prev.admin || e.target.checked,
                    };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                Sub-Admin
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Display Purchase Orders"
                  name="dsp"
                  value={userData.dsp}
                  checked={userData.dsp}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        //dsp: prev.admin || prev.user || e.target.checked,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Display Purchase
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Orders"
                  name="orders"
                  // value={userData.orders}
                  // checked={userData.orders}
                  value={true}
                  checked={true}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: true,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  PO Summary
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Box Details"
                  name="boxDetails"
                  value={userData.boxDetails}
                  checked={userData.boxDetails}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Box Details
                </label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Ship Info"
                  name="shipInfo"
                  value={userData.shipInfo}
                  checked={userData.shipInfo}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Ship Details
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="CSV"
                  name="csv"
                  value={userData.csv}
                  checked={userData.csv}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Transfer Data
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Entry"
                  name="entry"
                  value={userData.entry}
                  checked={userData.entry}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Box Entries
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Create User"
                  name="createUser"
                  value={userData.createUser}
                  checked={userData.createUser}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Create User
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="Entry Modification"
                  name="entryModification"
                  value={userData.entryModification}
                  checked={userData.entryModification}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Modify Entry
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="modifyUser"
                  name="modifyUser"
                  value={userData.modifyUser}
                  checked={userData.modifyUser}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Modify User
                </label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className=" form-check-lg">
                <input
                  id="largeCheckbox"
                  type="checkbox"
                  className="flex-lg-fil me-2 form-check-input"
                  placeholder="BoxType"
                  name="boxType"
                  value={userData.boxType}
                  checked={userData.boxType}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
                <label
                  className="form-check-label"
                  onClick={(e) => e.preventDefault()}
                >
                  Box Type
                </label>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-12">
            <input
              id="largeCheckbox"
              type="checkbox"
              className="flex-lg-fil me-2 form-check-input"
              placeholder="loadPO"
              name="loadPO"
              value={userData.loadPO}
              checked={userData.loadPO}
              onChange={(e) =>
                setUserData((prev) => {
                  return { ...prev, [e.target.name]: e.target.checked };
                })
              }
            />
            <label
              className="form-check-label"
              onClick={(e) => e.preventDefault()}
            >
              Load Data
            </label>
          </div>
          <div className="col-md-12">
            <input
              id="largeCheckbox"
              type="checkbox"
              className="flex-lg-fil me-2 form-check-input"
              placeholder="ShipmentLoading"
              name="shipmentLoading"
              value={userData.shipmentLoading}
              checked={userData.shipmentLoading}
              onChange={(e) =>
                setUserData((prev) => {
                  return { ...prev, [e.target.name]: e.target.checked };
                })
              }
            />
            <label
              className="form-check-label"
              onClick={(e) => e.preventDefault()}
            >
              ShipmentLoading
            </label>
          </div>
          <div className="col-md-12">
            <input
              id="largeCheckbox"
              type="checkbox"
              className="flex-lg-fil me-2 form-check-input"
              placeholder="BarCodePdf"
              name="barcodePdf"
              value={userData.barcodePdf}
              checked={userData.barcodePdf}
              onChange={(e) =>
                setUserData((prev) => {
                  return { ...prev, [e.target.name]: e.target.checked };
                })
              }
            />
            <label
              className="form-check-label"
              onClick={(e) => e.preventDefault()}
            >
              Barcodepdf
            </label>
          </div>
          <div className="col-md-12">
            <input
              id="largeCheckbox"
              type="checkbox"
              className="flex-lg-fil me-2 form-check-input"
              placeholder="ExFactory"
              name="exFactory"
              value={userData.exFactory}
              checked={userData.exFactory}
              onChange={(e) =>
                setUserData((prev) => {
                  return { ...prev, [e.target.name]: e.target.checked };
                })
              }
            />
            <label
              className="form-check-label"
              onClick={(e) => e.preventDefault()}
            >
              ExFactory
            </label>
          </div>
          <div className=" form-check-lg">
            <input
              id="largeCheckbox"
              type="checkbox"
              className="flex-lg-fil me-2 form-check-input"
              placeholder="RangeCode"
              name="rangeCode"
              value={userData.rangeCode}
              checked={userData.rangeCode}
              onChange={(e) =>
                setUserData((prev) => {
                  return { ...prev, [e.target.name]: e.target.checked };
                })
              }
            />
            <label
              className="form-check-label"
              onClick={(e) => e.preventDefault()}
            >
              Range Code
            </label>
          </div>

          <div className="">
            <input
              id="largeCheckbox"
              type="checkbox"
              className="flex-lg-fil me-2 form-check-input"
              placeholder="reports"
              name="reports"
              value={userData.reports}
              checked={userData.reports}
              onChange={(e) =>
                setUserData((prev) => {
                  return { ...prev, [e.target.name]: e.target.checked };
                })
              }
            />
            <label
              className="form-check-label"
              onClick={(e) => e.preventDefault()}
            >
              Reports
            </label>
          </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary fw-bold me-2"
                onClick={UpdateUser}
              >
                Save
              </button>
              <button
                className="ms-4 btn btn-danger fw-bold"
                onClick={handleDelete}
              >
                Cancel
              </button>
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
    </div>
  );
};

export default EditUser;
